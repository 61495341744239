const initialState = {
    messages: [],
    messagesLoading: false,

    sendMessageLoading: false,

    readMessageLoading: false,
}

const chat = (state = initialState, action) => {
    switch(action.type) {
        // GET_MESSAGES_HOTEL_APP_START
        case 'GET_MESSAGES_HOTEL_APP_REQUEST':
            return {
                ...state,
                messagesLoading: true,
            }
        case 'GET_MESSAGES_HOTEL_APP_SUCCESS':
            return {
                ...state,
                messages: action.payload,
                messagesLoading: false,
            }
        case 'GET_MESSAGES_HOTEL_APP_FAILURE':
            return {
                ...state,
                messagesLoading: false,
            }
        // GET_MESSAGES_HOTEL_APP_END

        // CLEAR_MESSAGES_HOTEL_APP_START
        case 'CLEAR_MESSAGES_HOTEL_APP':
            return {
                ...state,
                messages: [],
                messagesLoading: false,
            }
        // CLEAR_MESSAGES_HOTEL_APP_END

        // SEND_MESSAGE_HOTEL_APP_START
        case 'SEND_MESSAGE_HOTEL_APP_REQUEST':
            return {
                ...state,
                sendMessageLoading: true,
            }
        case 'SEND_MESSAGE_HOTEL_APP_SUCCESS': {
            const { messages } = state

            return {
                ...state,
                messages: [...messages, action.payload],
                sendMessageLoading: false,
            }
        }
        case 'SEND_MESSAGE_HOTEL_APP_FAILURE':
            return {
                ...state,
                sendMessageLoading: false,
            }
        // SEND_MESSAGE_HOTEL_APP_END

        // READ_MESSAGE_HOTEL_APP_START
        case 'READ_MESSAGE_HOTEL_APP_REQUEST':
            return {
                ...state,
                readMessageLoading: true,
            }
        case 'READ_MESSAGE_HOTEL_APP_SUCCESS':
            return {
                ...state,
                readMessageLoading: false,
            }
        case 'READ_MESSAGE_HOTEL_APP_FAILURE':
            return {
                ...state,
                readMessageLoading: false,
            }
        // READ_MESSAGE_HOTEL_APP_END

        // MESSAGE_RECEIVED_HOTEL_APP_START
        case 'MESSAGE_RECEIVED_HOTEL_APP': {
            const { messages } = state

            return {
                ...state,
                messages: [...messages, action.payload],
            }
        }
        // MESSAGE_RECEIVED_HOTEL_APP_END

        // MESSAGE_READ_HOTEL_APP_START
        case 'MESSAGE_READ_HOTEL_APP': {
            const { messages } = state

            const messagesIndex = messages.findIndex((message) => message.id === action.payload.message_id)

            if (messages[messagesIndex]) {
                messages[messagesIndex].read = true
            }

            return {
                ...state,
                messages: [...messages],
            }
        }
        // MESSAGE_READ_HOTEL_APP_END

        default:
            return state
    }
}

export default chat
