import * as React from 'react'

function SvgComponent(props) {
	const {
		color,
	} = props

	return (
		<svg
			width={25}
			height={25}
			viewBox="0 0 25 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M6.75 2.75l-3 4v14a2 2 0 002 2h14a2 2 0 002-2v-14l-3-4h-12zM3.75 6.75h18M16.75 10.75a4 4 0 11-8 0"
				stroke={color}
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}

const MemoSvgComponent = React.memo(SvgComponent)

export default MemoSvgComponent
