import * as React from 'react'

function SvgComponent(props) {
	return (
		<svg
			width={11}
			height={10}
			viewBox="0 0 11 10"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M7.158 5l2.735-2.734a.812.812 0 000-1.204L9.29.462a.812.812 0 00-1.203 0L5.354 3.195 2.592.461a.812.812 0 00-1.203 0l-.602.601a.812.812 0 000 1.204L3.521 5 .787 7.762a.812.812 0 000 1.203l.602.601a.812.812 0 001.203 0l2.762-2.734 2.734 2.734a.812.812 0 001.203 0l.602-.601a.812.812 0 000-1.203L7.158 5z"
				fill="#9CA7B5"
			/>
		</svg>
	)
}

const MemoSvgComponent = React.memo(SvgComponent)

export default MemoSvgComponent
