const initialState = {
    checkedSession: false,
    checkedSessionLoading: false,

    clientOutlet: null,

    currentOutlet: null,
    currentService: null,

    currentRoom: null,
    currentTable: null,
    currentUUID: null,
    currentLanguage: 'en',

    BASE_URL: 'https://sqrneo.com/api/v1/client/',
}

const session = (state = initialState, action) => {
    switch(action.type) {
        // CHECK_SESSION_START
        case 'CHECK_SESSION_REQUEST':
            return {
                ...state,
                BASE_URL: 'https://sqrneo.com/api/v1/client/',
                checkedSession: action.payload,
                checkedSessionLoading: true,
            }
        case 'CHECK_SESSION_SUCCESS':
            return {
                ...state,
                checkedSession: action.payload,
                checkedSessionLoading: false,
            }
        case 'CHECK_SESSION_FAILURE':
            return {
                ...state,
                checkedSession: action.payload,
                checkedSessionLoading: false,
            }
        // CHECK_SESSION_END

        // UPDATE_CLIENT_OUTLET_START
        case 'UPDATE_CLIENT_OUTLET':
            return {
                ...state,
                clientOutlet: { ...action.payload },
            }
        // UPDATE_CLIENT_OUTLET_END

        // UPDATE_SERVICE_OUTLET_START
        case 'UPDATE_SERVICE_OUTLET':
            return {
                ...state,
                currentOutlet: action.payload,
            }
        // UPDATE_SERVICE_OUTLET_END

        // UPDATE_SERVICE_START
        case 'UPDATE_SERVICE': {
            let url = 'https://sqrneo.com/api/v1/client/'

            if (action.payload.service === 'hotelApp') {
                url = `https://sqrroom.sqrneo.com/api/shop/v3/slug/${action.payload.slug}/`
            } else if (action.payload.service === 'waiterApp') {
                url = `https://sqrmenu.sqrneo.com/api/shop/v2/slug/${action.payload.slug}/`
            }

            return {
                ...state,
                currentService: action.payload.service,
                BASE_URL: url,
            }
        }
        // UPDATE_SERVICE_END

        // UPDATE_ROOM_START
        case 'UPDATE_ROOM':
            return {
                ...state,
                currentRoom: action.payload,
            }
        // UPDATE_ROOM_END

        // UPDATE_TABLE_START
        case 'UPDATE_TABLE':
            return {
                ...state,
                currentTable: action.payload,
            }
        // UPDATE_TABLE_END

        // UPDATE_UUID_START
        case 'UPDATE_UUID':
            return {
                ...state,
                currentUUID: action.payload,
            }
        // UPDATE_UUID_END

        // UPDATE_LANGUAGE_START
        case 'UPDATE_LANGUAGE':
            return {
                ...state,
                currentLanguage: action.payload,
            }
        // UPDATE_LANGUAGE_END

        default:
            return state
    }
}

export default session
