import React from 'react'

import OrderListItem from '../OrderListItem'

import styles from './OrderList.module.scss'

const OrderList = (props) =>  {
    const {
        data,
        onUpdateOrder,
    } = props

    return (
        <div className={styles.container}>
            {
                data.map((item, index) => (
                    <OrderListItem
                        key={index}
                        item={item}
                        onUpdateOrder={onUpdateOrder}
                    />
                ))
            }
        </div>
    )
}

export default OrderList
