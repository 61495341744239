import React from 'react'

import { useSelector } from 'react-redux'

import { NavLink } from 'react-router-dom'

import Headroom from 'react-headroom'

import queryString from 'query-string'

import { AppButton } from 'components'

import {
    BackIcon,
    HomeIcon,
    LanguagesIcon,
    CategoriesIcon,
} from 'assets/icons'

import styles from './AppHeader.module.scss'

const AppHeader = (props) => {
    const {
        type,
        title,
        subtitle,
        className = '',
        onClose,
    } = props

    const clientOutlet = useSelector((state) => state.session.clientOutlet)
    const currentOutlet = useSelector((state) => state.session.currentOutlet)
	const currentRoom = useSelector((state) => state.session.currentRoom)
	const currentTable = useSelector((state) => state.session.currentTable)

    const renderHeader = () => {
        if (type === 'back') {
            return (
                <div
                    className={`${styles['type-back']} ${className}`}
                    // style={{
                    //     // boxShadow: title ? 'none' : '0px 1px 4px rgba(0, 0, 0, 0.25)',
                    //     borderTopRightRadius: title ? 20 : 0,
                    //     borderTopLeftRadius: title ? 20 : 0,
                    // }}
                >
                    <AppButton
                        className={styles['type-back_button-back']}
                        onClick={onClose}
                    >
                        <BackIcon />

                        <p className={styles['type-back_button-back_title']}>Back</p>
                    </AppButton>

                    {title && <p className={styles['type-back_title']}>{title}</p>}
                </div>
            )
        } else if (type === 'back-fixed') {
            return (
                // <Headroom
                //     style={{
                //         zIndex: 4,
                //         WebkitTransition: 'all 0.5s ease-in-out 0s',
                //         MozTransition: 'all 0.5s ease-in-out 0s',
                //         OTransition: 'all 0.5s ease-in-out 0s',
                //         transition: 'all 0.5s ease-in-out 0s',
                //         position: 'fixed',
                //         height: 58,
                //     }}
                // >
                <div style={{ position: 'fixed', top: 0, left: 0, height: 58, width: '100%', zIndex: 100 }} >
                    <div
                        className={`${styles['type-back']} ${className}`}
                        // style={{
                        //     // boxShadow: title ? 'none' : '0px 1px 4px rgba(0, 0, 0, 0.25)',
                        //     borderTopRightRadius: title ? 20 : 0,
                        //     borderTopLeftRadius: title ? 20 : 0,
                        // }}
                    >
                        <AppButton
                            className={styles['type-back_button-back']}
                            style={{ left: 16 }}
                            onClick={onClose}
                        >
                            <BackIcon />

                            <p className={styles['type-back_button-back_title']}>Back</p>
                        </AppButton>

                        {title && <p className={styles['type-back_title']}>{title}</p>}
                    </div>
                {/* </Headroom> */}
                </div>
            )
        } else if (type === 'title') {
            return (
                <div className={`${styles['type-title']} ${className}`}>
                    {title && <p className={styles['type-title_title']}>{title}</p>}

                    {
                        subtitle && (
                            <p
                                className={styles['type-title_subtitle']}
                                // style={{ marginTop: title ? 0 : -7 }}
                            >
                                {subtitle}
                            </p>
                        )
                    }
                </div>
            )
        } else if (type === 'navbar') {
            const query = queryString.parse(window.location.hash)

            return (
                <Headroom
                    style={{
                        zIndex: 4,
                        WebkitTransition: 'all 0.5s ease-in-out 0s',
                        MozTransition: 'all 0.5s ease-in-out 0s',
                        OTransition: 'all 0.5s ease-in-out 0s',
                        transition: 'all 0.5s ease-in-out 0s',
                        position: 'fixed',
                        height: 58,
                        marginTop: 
                            (currentRoom && currentOutlet.slug === currentRoom.slug)
                            ||
                            (currentTable && currentOutlet.slug === currentTable.slug)
                            ? 58 : 0,
                    }}
                >
                    <div className={`${styles['type-navbar']} ${className}`}>
                        {
                            // !currentRoom
                            // && !currentTable
                            // ? (
                                // <NavLink to="/">
                                //     <HomeIcon />
                                // </NavLink>
                            // ) : (
                                // <p className={styles['type-navbar_title']}>{title}</p>
                            // )
                            <AppButton onClick={() => {}}>
                                {
                                    clientOutlet.services
                                    && clientOutlet.services.length > 1
                                    && (
                                        <NavLink
                                            to="/services"
                                            className={styles['type-navbar_icon']}
                                        >
                                            <HomeIcon />
                                        </NavLink>
                                    )
                                }

                                {title && <p className={styles['type-navbar_title']}>{title}</p>}
                            </AppButton>
                        }

                        <div />

                        {
                            currentOutlet?.upgrades?.languages && (
                                <NavLink
                                    to="/languages"
                                    style={{ marginTop: 3 }}
                                >
                                    <LanguagesIcon />
                                </NavLink>
                            )
                        }

                        <NavLink
                            to={`/categories/${query['/menu?masterCategories'] || 'all'}`}
                            style={{ marginTop: 2 }}
                        >
                            <CategoriesIcon />
                        </NavLink>
                    </div>
                </Headroom>
            )
        }
    }

    return renderHeader()
}

export default AppHeader
