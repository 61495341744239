import React, {
    useState,
    useRef,
} from 'react'

import MasterCategoriesListItem from '../MasterCategoriesListItem'

import styles from './MasterCategoriesList.module.scss'

const MasterCategoriesList = (props) => {
    const {
        data,
        selectedMasterCategory,
        onSelectMasterCategory,
    } = props

    const [isDrown, setIsDrown] = useState(false)
    const [startX, setStartX] = useState(null)
    const [scrollLeft, setScrollLeft] = useState(null)

    const sliderRef = useRef()

    const onMouseDown = (event) => {
        setIsDrown(true)
        setStartX(event.pageX - sliderRef.current.offsetLeft)
        setScrollLeft(sliderRef.current.scrollLeft)
    }

    const onTouchStart = (event) => {
        setIsDrown(true)
        setStartX(event.touches[0].pageX - sliderRef.current.offsetLeft)
        setScrollLeft(sliderRef.current.scrollLeft)
    }

    const onMouseMove = (event) => {
        if (!isDrown) return

        event.preventDefault()

        const x = event.pageX - sliderRef.current.offsetLeft
        const walk = (x - startX) * 3

        sliderRef.current.scrollLeft = scrollLeft - walk
    }

    const onTouchMove = (event) => {
        if (!isDrown) return

        const x = event.touches[0].pageX - sliderRef.current.offsetLeft
        const walk = (x - startX) * 3

        sliderRef.current.scrollLeft = scrollLeft - walk        
    }

    const renderList = () => {
        if (!!data.length) {
            return (
                <div
                    className={styles.container}
                    ref={sliderRef}
                    onMouseDown={(event) => onMouseDown(event)}
                    onTouchStart={(event) => onTouchStart(event)}
                    onMouseLeave={() => setIsDrown(false)}
                    onMouseUp={() => setIsDrown(false)}
                    onTouchEnd={() => setIsDrown(false)}
                    onMouseMove={(event) => onMouseMove(event)}
                    onTouchMove={(event) => onTouchMove(event)}
                >
                    {
                        data.map((item, index) => (
                            <MasterCategoriesListItem
                                key={index}
                                item={item}
                                selectedMasterCategory={selectedMasterCategory}
                                onSelectMasterCategory={onSelectMasterCategory}
                            />
                        ))
                    }
                </div>
            )
        }

        return null
    }

    return renderList()
}

export default MasterCategoriesList
