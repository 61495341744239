import LanguagesService from 'services/languages'

import { setItem } from 'utils/storage'

function getLanguagesAction(params = {}, callback) {
    return async (dispatch) => {
        dispatch({ type: 'GET_LANGUAGES_REQUEST' })

        try {
            const data = await LanguagesService.getLanguages()

            let languages = []

            for (let key in data.data) {
                languages.push({
                    key: key,
                    language: data.data[key],
                })
            }

            dispatch({
                type: 'GET_LANGUAGES_SUCCESS',
                payload: languages,
            })

            if (callback) callback()
        } catch (error) {
            dispatch({ type: 'GET_LANGUAGES_FAILURE' })
        }
    }
}

function updateLanguageAction(params = {}, callback) {
    return (dispatch) => {
        setItem('sqr_neo_language', params.language)

        dispatch({
            type: 'UPDATE_LANGUAGE',
            payload: params.language,
        })

        if (callback) callback()
    }
}

export {
    getLanguagesAction,
    updateLanguageAction,
}
