import * as React from "react"

function SvgComponent(props) {
	const {
		color='#FFD15A',
	} = props

	return (
		<svg
			width={55}
			height={53}
			viewBox="0 0 55 53"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g filter="url(#prefix__filter0_d)">
				<path
					d="M24.82 10.329c1.108-2.203 4.252-2.203 5.36 0l3.569 7.095a3 3 0 002.254 1.622l7.891 1.132c2.477.355 3.456 3.409 1.647 5.138L39.908 30.7a3 3 0 00-.882 2.685l1.338 7.66c.427 2.443-2.127 4.32-4.33 3.181l-7.158-3.694a3 3 0 00-2.752 0l-7.157 3.694c-2.204 1.138-4.758-.738-4.331-3.182l1.338-7.66a3 3 0 00-.882-2.684l-5.633-5.385c-1.81-1.73-.83-4.783 1.647-5.138l7.891-1.132a3 3 0 002.254-1.622l3.569-7.095z"
					fill={color}
				/>
			</g>

			<defs>
				<filter
					id="prefix__filter0_d"
					x={0.529}
					y={0.677}
					width={53.943}
					height={51.892}
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity={0} result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					/>
					<feOffset />
					<feGaussianBlur stdDeviation={4} />
					<feColorMatrix values="0 0 0 0 1 0 0 0 0 0.9 0 0 0 0 0 0 0 0 0.5 0" />
					<feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
					<feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
				</filter>
			</defs>
		</svg>
	)
}

const MemoSvgComponent = React.memo(SvgComponent)

export default MemoSvgComponent
