import React, {
    useState,
    useEffect,
} from 'react'

import {
    useDispatch,
    useSelector,
} from 'react-redux'

import queryString from 'query-string'

import {
    getProductsAction,
    getProductAction,
    clearProductAction,
} from 'store/actions/hotelApp/menu'

import { addOrderAction } from 'store/actions/hotelApp/orders'

import { getMasterCategoriesAction } from 'store/actions/hotelApp/categories'

import {
    AppContainer,
    AppHeader,
    AppButton,
    AppSpinner,
} from 'components'

import { ModalOrder } from 'components/HotelApp'

import {
    MinusIcon,
    PlusIcon,
} from 'assets/icons'

import {
    translate,
    getCurrency,
} from 'utils/helpers'

import { isArray } from 'utils/types'

import styles from './MenuItem.module.scss'

const MenuItem = (props) => {
    const {
        history,
        location,
        match: { params },
    } = props

    const [isImageLoaded, setIsImageLoaded] = useState(false)

    const [quantity, setQuantity] = useState(1)
    const [isIncreaseQuantityClicked, setIsIncreaseQuantityClicked] = useState(false)
    const [isDecreaseQuantityClicked, setIsDecreaseQuantityClicked] = useState(false)

    const [isModalOrderVisible, setIsModalOrderVisible] = useState(false)

    const currentRoom = useSelector((state) => state.session.currentRoom)
    const currentOutlet = useSelector((state) => state.session.currentOutlet)

    const products = useSelector((state) => state.hotelAppMenu.products)
    const productsLoading = useSelector((state) => state.hotelAppMenu.productsLoading)

    const product = useSelector((state) => state.hotelAppMenu.product)

    const masterCategories = useSelector((state) => state.hotelAppCategories.masterCategories)

    const dispatch = useDispatch()

    useEffect(() => {
        // dispatch(clearProductAction())
        window.scrollTo(0, 0)

        const getProductsParams = {}

        const getProductParams = {
            id: parseInt(params.id, 10),
        }

        if (location.search) {
            const query = queryString.parse(location.search)

            if (query.categories) {
                getProductsParams.id = query.categories

                if (!products.length) {
                    dispatch(getProductsAction(getProductsParams, () => {
                        dispatch(getProductAction(getProductParams))
                    }))
                } else {
                    dispatch(getProductAction(getProductParams))
                }
            } else if (query.masterCategories) {
                getProductsParams.id = query.masterCategories
                getProductsParams.master = true

                if (!products.length) {
                    dispatch(getProductsAction(getProductsParams, () => {
                        dispatch(getProductAction(getProductParams))
                    }))
                } else {
                    dispatch(getProductAction(getProductParams))
                }
            }
        } else {
            if (!products.length) {
                dispatch(getProductsAction(getProductsParams, () => {
                    dispatch(getProductAction(getProductParams))
                }))
            } else {
                dispatch(getProductAction(getProductParams))
            }
        }

        if (!masterCategories.length) dispatch(getMasterCategoriesAction())

        // return () => {
        //     dispatch(clearProductAction())
        // }
    }, [])

    useEffect(() => {
        if (isModalOrderVisible) {
            setTimeout(() => document.body.style.overflowY = 'hidden', 500)
        } else {
            document.body.style.overflowY = 'scroll'
        }
    }, [isModalOrderVisible])

    const onChangeQuantity = (event, value) => {
        event.preventDefault()

        const newQuantity = quantity + value

        if (newQuantity > 0) setQuantity(newQuantity)

        setIsIncreaseQuantityClicked(false)
        setIsDecreaseQuantityClicked(false)
    }

    const onAddOrder = (order) => {
        const {
            note,
            additions,
        } = order

        const addOrderParams = {
            room_id: currentRoom.id,
            note: note,
            grand_total: product.price * quantity,
            grand_quantity: quantity,
            products: [{
                product_id: product.id,
                quantity: quantity,
                additions: additions,
                product: [{
                    images: product.images,
                    description: product.description,
                    price: product.price,
                }],
            }],
            name: product.name,
            weight: product.weight,
            categories: product.categories,
            status: 'New',
        }

        dispatch(addOrderAction(addOrderParams, () => {
            setIsModalOrderVisible(false)

            history.goBack()
        }))
    }

    const renderItem = () => {
        if (productsLoading) return <AppSpinner />

        if (product && product.id === parseInt(params.id, 10)) {
            return (
                <div className={styles.image_container}>
                    <img
                        className={styles.image}
                        src={product.images[0]}
                        alt={translate(product.name)}
                        onLoad={() => setIsImageLoaded(true)}
                    />

                    {isImageLoaded && <p className={styles.image_title}>{translate(product.categories[0].name)}</p>}

                    {
                        isImageLoaded && (
                            <>
                                <p className={styles.title}>{translate(product.name)}</p>
                
                                <div className={styles.info}>
                                    <p className={styles.info_price}>{getCurrency(product.price)}</p>

                                    {product.weight && <p className={styles.info_weight}>{product.weight} g</p>}
                                </div>

                                {
                                    product.description
                                    && !isArray(product.description)
                                    && (
                                        <p className={styles.description}>
                                            {translate(product.description)}
                                        </p>
                                    )
                                }
                            
                                {
                                    currentRoom && currentOutlet.slug === currentRoom.slug && (
                                        <div className={styles.footer}>
                                            <div className={styles['footer_buttons-quantity']}>
                                                <AppButton
                                                    className={styles['footer_buttons-quantity_button']}
                                                    style={{
                                                        backgroundColor:
                                                        isDecreaseQuantityClicked
                                                            ? '#2868C8'
                                                            : '#E7EDF8'
                                                    }}
                                                    onMouseDown={() => setIsDecreaseQuantityClicked(true)}
                                                    onMouseLeave={() => setIsDecreaseQuantityClicked(false)}
                                                    onTouchStart={() => setIsDecreaseQuantityClicked(true)}
                                                    onClick={(event) => onChangeQuantity(event, -1)}
                                                >
                                                    <MinusIcon color={isDecreaseQuantityClicked ? '#FFFFFF' : '#2868C8'} />
                                                </AppButton>

                                                <span className={styles['footer_quantity']}>{quantity}</span>

                                                <AppButton
                                                    className={styles['footer_buttons-quantity_button']}
                                                    style={{
                                                        backgroundColor:
                                                        isIncreaseQuantityClicked
                                                            ? '#2868C8'
                                                            : '#E7EDF8'
                                                    }}
                                                    onMouseDown={() => setIsIncreaseQuantityClicked(true)}
                                                    onMouseLeave={() => setIsIncreaseQuantityClicked(false)}
                                                    onTouchStart={() => setIsIncreaseQuantityClicked(true)}
                                                    onClick={(event) => onChangeQuantity(event, 1)}
                                                >
                                                    <PlusIcon color={isIncreaseQuantityClicked ? '#FFFFFF' : '#2868C8'} />
                                                </AppButton>
                                            </div>

                                            <AppButton
                                                className={styles['footer_button-confirm']}
                                                onClick={() => setIsModalOrderVisible(true)}
                                            >
                                                Add to order
                                            </AppButton>
                                        </div>
                                    )
                                }
                            </>
                        )
                    }
                </div>
            )
        }

        return null
    }

    const renderModalOrder = () => {
        if (isModalOrderVisible) {
            return (
                <ModalOrder
                    selectedProduct={product}
                    onAddOrder={onAddOrder}
                    onClose={() => setIsModalOrderVisible(false)}    
                />
            )
        }

        return null
    }

    return (
        <AppContainer
            className={styles.container}
            style={{ paddingTop: currentRoom && currentOutlet.slug === currentRoom.slug ? 156 : 58 }}
        >
            <AppHeader
                type="back-fixed"
                onClose={() => history.goBack()}
            />

            {renderItem()}

            {renderModalOrder()}
        </AppContainer>
    )
}

export default MenuItem
