import FeaturesService from 'services/waiterApp/features'

function callWaiterAction(params = {}, callback) {
    return async (dispatch) => {
        dispatch({ type: 'CALL_WAITER_WAITER_APP_REQUEST' })

        try {
            await FeaturesService.callWaiter(params)

            dispatch({ type: 'CALL_WAITER_WAITER_APP_SUCCESS' })

            if (callback) callback()
        } catch (error) {
            dispatch({ type: 'CALL_WAITER_WAITER_APP_FAILURE' })
        }
    }
}

function billRequestAction(params = {}, callback) {
    return async (dispatch) => {
        dispatch({ type: 'BILL_REQUEST_WAITER_APP_REQUEST' })

        try {
            await FeaturesService.billRequest(params)

            dispatch({ type: 'BILL_REQUEST_WAITER_APP_SUCCESS' })

            if (callback) callback()
        } catch (error) {
            dispatch({ type: 'BILL_REQUEST_WAITER_APP_FAILURE' })
        }
    }
}

function leaveFeedbackAction(params = {}, callback) {
    return async (dispatch) => {
        dispatch({ type: 'LEAVE_FEEDBACK_WAITER_APP_REQUEST' })

        try {
            await FeaturesService.leaveFeedback(params)

            dispatch({ type: 'LEAVE_FEEDBACK_WAITER_APP_SUCCESS' })

            if (callback) callback()
        } catch (error) {
            dispatch({ type: 'LEAVE_FEEDBACK_WAITER_APP_FAILURE' })
        }
    }
}

export {
    callWaiterAction,
    billRequestAction,
    leaveFeedbackAction,
}
