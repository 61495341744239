import React from 'react'

import { AppButton } from 'components'

import styles from './LanguagesListItem.module.scss'

const LanguagesListItem = (props) => {
    const {
        item,
        onSelectLanguage,
    } = props

    return (
        <AppButton
            className={styles.container}
            onClick={() => onSelectLanguage(item.key)}
        >
            {item.language}
        </AppButton>
    )
}

export default LanguagesListItem
