import { updateBaseURL } from 'services/instance'

import SessionService from 'services/session'

import {
    getItem,
    setItem,
    removeItem,
} from 'utils/storage'

import { generateUUID } from 'utils/helpers'

function checkSessionAction(params = {}, callback) {
    return async (dispatch) => {
        try {
            dispatch({ type: 'CHECK_SESSION_REQUEST' })

            updateBaseURL()

            const slug = getItem('slug')

            const data = await SessionService.getOutlet(slug)
            
            const outletData = data.data.data

            if (outletData) {
                outletData.services = []

                if (!!outletData.sqrmenu_outlets.length) {
                    outletData.sqrmenu_outlets.map((outlet) => {
                        outlet.service = 'waiterApp'
                        outletData.services.push(outlet)
                    })
                }

                if (!!outletData.sqrroom_outlets.length) {
                    outletData.sqrroom_outlets.map((outlet) => {
                        outlet.service = 'hotelApp'
                        outletData.services.push(outlet)
                    })
                }

                const storageClientSlug = getItem('sqr_neo_client_slug')

                if (
                    storageClientSlug
                    && storageClientSlug !== outletData.slug
                ) {
					removeItem('sqr_neo_client_slug')
                    removeItem('sqr_neo_language')
                    removeItem('sqr_neo_service')
                    removeItem('sqr_neo_service_slug')
                    removeItem('sqr_neo_room')
                    removeItem('sqr_neo_table')
                    removeItem('sqr_neo_uuid')
                    removeItem('sqr_neo_orders')
                }

                setItem('sqr_neo_client_slug', outletData.slug)

                dispatch({  
                    type: 'UPDATE_CLIENT_OUTLET',
                    payload: outletData,
                })

                const storageLanguage = getItem('sqr_neo_language')

                if (storageLanguage) {
                    dispatch({
                        type: 'UPDATE_LANGUAGE',
                        payload: storageLanguage,
                    })
                } else {
                    setItem('sqr_neo_language', 'en')

                    dispatch({
                        type: 'UPDATE_LANGUAGE',
                        payload: 'en',
                    })
                }

                // CHECK SERVICE SLUG

                const storageServiceSlug = getItem('sqr_neo_service_slug')
    
                if (storageServiceSlug) {
                    const currentServiceOutlet = outletData.services.find((service) => service.slug === storageServiceSlug)

                    if (currentServiceOutlet) {    
                        dispatch({
                            type: 'UPDATE_SERVICE_OUTLET',
                            payload: currentServiceOutlet,
                        })
    
                        const urlParams = new URLSearchParams(window.location.search)
    
                        const urlParamsRoomId = urlParams.get('room_id')
                        const urlParamsTableId = urlParams.get('table')
    
                        const storageService = getItem('sqr_neo_service')
                        const storageRoom = getItem('sqr_neo_room')
                        const storageTable = getItem('sqr_neo_table')
    
                        if (urlParamsRoomId) {
                            setItem('sqr_neo_service', 'hotelApp')

                            dispatch({
                                type: 'UPDATE_SERVICE',
                                payload: {
                                    service: 'hotelApp',
                                    slug: currentServiceOutlet.slug,
                                },
                            })
    
                            updateBaseURL()
    
                            if (storageRoom && urlParamsRoomId !== storageRoom.id) {
                                removeItem('sqr_neo_room')
                                removeItem('sqr_neo_uuid')
                                removeItem('sqr_neo_orders')
                            }
    
                            if (storageTable) {
                                removeItem('sqr_neo_table')
                                removeItem('sqr_neo_uuid')
                                removeItem('sqr_neo_orders')
                            }
    
                            setItem('sqr_neo_room', {
                                id: urlParamsRoomId,
                                slug: currentServiceOutlet.slug,
                            })
    
                            dispatch({
                                type: 'UPDATE_ROOM',
                                payload: {
                                    id: parseInt(urlParamsRoomId, 10),
                                    slug: currentServiceOutlet.slug,
                                },
                            })

                            window.history.pushState(null, null, window.location.pathname)
                        } else if (urlParamsTableId) {
                            setItem('sqr_neo_service', 'waiterApp')
    
                            dispatch({
                                type: 'UPDATE_SERVICE',
                                payload: {
                                    service: 'waiterApp',
                                    slug: currentServiceOutlet.slug,
                                },
                            })
    
                            updateBaseURL()
    
                            if (storageTable && urlParamsTableId !== storageTable.id) {
                                removeItem('sqr_neo_table')
                                removeItem('sqr_neo_uuid')
                                removeItem('sqr_neo_orders')
                            }
    
                            if (storageRoom) {
                                removeItem('sqr_neo_room')
                                removeItem('sqr_neo_uuid')
                                removeItem('sqr_neo_orders')
                            }
    
                            setItem('sqr_neo_table', {
                                id: urlParamsTableId,
                                slug: currentServiceOutlet.slug,
                            })
    
                            dispatch({
                                type: 'UPDATE_TABLE',
                                payload: {
                                    id: parseInt(urlParamsTableId, 10),
                                    slug: currentServiceOutlet.slug,
                                },
                            })

                            window.history.pushState(null, null, window.location.pathname)
                        } else {
                            if (storageService) {
                                dispatch({
                                    type: 'UPDATE_SERVICE',
                                    payload: {
                                        service: storageService,
                                        slug: currentServiceOutlet.slug,
                                    },
                                })
        
                                updateBaseURL()
                            }

                            if (storageRoom) {
                                dispatch({
                                    type: 'UPDATE_ROOM',
                                    payload: {
                                        id: storageRoom.id,
                                        slug: storageRoom.slug,
                                    },
                                })
                            } else if (storageTable) {
                                dispatch({
                                    type: 'UPDATE_TABLE',
                                    payload: {
                                        id: storageTable.id,
                                        slug: storageTable.slug,
                                    },
                                })
                            }
                        }
    
                        const storageUUID = getItem('sqr_neo_uuid')
    
                        if (storageUUID) {
                            dispatch({
                                type: 'UPDATE_UUID',
                                payload: storageUUID,
                            })
                        } else {
                            const uuid = generateUUID()
    
                            setItem('sqr_neo_uuid', uuid)
    
                            dispatch({
                                type: 'UPDATE_UUID',
                                payload: uuid,
                            })
                        }
                    } else {
                        removeItem('sqr_neo_service_slug')
                        removeItem('sqr_neo_service')
                        removeItem('sqr_neo_room')
                        removeItem('sqr_neo_table')
                        removeItem('sqr_neo_uuid')
                        removeItem('sqr_neo_orders')

                        dispatch({
                            type: 'CHECK_SESSION_SUCCESS',
                            payload: false,
                        })
                    }
                } else {
                    removeItem('sqr_neo_service')
                    removeItem('sqr_neo_room')
                    removeItem('sqr_neo_table')
                    removeItem('sqr_neo_uuid')
                    removeItem('sqr_neo_orders')

                    const currentServiceOutlet = outletData.services.find((service) => service.slug === slug)

                    if (currentServiceOutlet) {
                        setItem('sqr_neo_service_slug', slug)

                        dispatch({
                            type: 'UPDATE_SERVICE_OUTLET',
                            payload: currentServiceOutlet,
                        })
    
                        const urlParams = new URLSearchParams(window.location.search)
    
                        const urlParamsRoomId = urlParams.get('room_id')
                        const urlParamsTableId = urlParams.get('table')
    
                        const storageRoom = getItem('sqr_neo_room')
                        const storageTable = getItem('sqr_neo_table')
    
                        if (urlParamsRoomId) {
                            setItem('sqr_neo_service', 'hotelApp')
    
                            dispatch({
                                type: 'UPDATE_SERVICE',
                                payload: {
                                    service: 'hotelApp',
                                    slug: currentServiceOutlet.slug,
                                },
                            })
    
                            updateBaseURL()
    
                            if (storageRoom && urlParamsRoomId !== storageRoom.id) {
                                removeItem('sqr_neo_room')
                                removeItem('sqr_neo_uuid')
                                removeItem('sqr_neo_orders')
                            }
    
                            if (storageTable) {
                                removeItem('sqr_neo_table')
                                removeItem('sqr_neo_uuid')
                                removeItem('sqr_neo_orders')
                            }
    
                            setItem('sqr_neo_room', {
                                id: urlParamsRoomId,
                                slug: currentServiceOutlet.slug,
                            })
    
                            dispatch({
                                type: 'UPDATE_ROOM',
                                payload: {
                                    id: parseInt(urlParamsRoomId, 10),
                                    slug: currentServiceOutlet.slug,
                                },
                            })

                            window.history.pushState(null, null, window.location.pathname)
                        } else if (urlParamsTableId) {
                            setItem('sqr_neo_service', 'waiterApp')
    
                            dispatch({
                                type: 'UPDATE_SERVICE',
                                payload: {
                                    service: 'waiterApp',
                                    slug: currentServiceOutlet.slug,
                                },
                            })
    
                            updateBaseURL()
    
                            if (storageTable && urlParamsTableId !== storageTable.id) {
                                removeItem('sqr_neo_table')
                                removeItem('sqr_neo_uuid')
                                removeItem('sqr_neo_orders')
                            }
    
                            if (storageRoom) {
                                removeItem('sqr_neo_room')
                                removeItem('sqr_neo_uuid')
                                removeItem('sqr_neo_orders')
                            }
    
                            setItem('sqr_neo_table', {
                                id: urlParamsTableId,
                                slug: currentServiceOutlet.slug,
                            })
    
                            dispatch({
                                type: 'UPDATE_TABLE',
                                payload: {
                                    id: parseInt(urlParamsTableId, 10),
                                    slug: currentServiceOutlet.slug,
                                },
                            })

                            window.history.pushState(null, null, window.location.pathname)
                        }
                        
                        setItem('sqr_neo_service', currentServiceOutlet.service)

                        dispatch({
                            type: 'UPDATE_SERVICE',
                            payload: {
                                service: currentServiceOutlet.service,
                                slug: currentServiceOutlet.slug,
                            },
                        })

                        updateBaseURL()

                        const uuid = generateUUID()

                        setItem('sqr_neo_uuid', uuid)

                        dispatch({
                            type: 'UPDATE_UUID',
                            payload: uuid,
                        })
                    }
                }

                // removeItem('slug')

                dispatch({
                    type: 'CHECK_SESSION_SUCCESS',
                    payload: true,
                })
            } else {
                dispatch({
                    type: 'CHECK_SESSION_SUCCESS',
                    payload: false,
                })
            }

            if (callback) callback()
        } catch (error) {
            console.log(error)

            dispatch({ type: 'CHECK_SESSION_FAILURE' })
        }
    }
}

function updateServiceAction(params = {}, callback) {
    return (dispatch) => {
        setItem('sqr_neo_service_slug', params.outlet.slug)
        setItem('sqr_neo_service', params.outlet.service)

        dispatch({
            type: 'UPDATE_SERVICE',
            payload: {
                service: params.outlet.service,
                slug: params.outlet.slug,
            },
        })

        dispatch({
            type: 'UPDATE_SERVICE_OUTLET',
            payload: params.outlet,
        })

        updateBaseURL()

        if (callback) callback()
    }
}

export {
    checkSessionAction,

    updateServiceAction,
}
