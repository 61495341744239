const initialState = {
    orders: [],
    ordersLoading: false,

    order: null,

    createOrderLoading: false,
}

const orders = (state = initialState, action) => {
    switch(action.type) {
        // GET_ORDERS_WAITER_APP_START
        case 'GET_ORDERS_WAITER_APP_REQUEST':
            return {
                ...state,
                ordersLoading: true,
            }
        case 'GET_ORDERS_WAITER_APP_SUCCESS':
            return {
                ...state,
                orders: action.payload,
                ordersLoading: false,
            }
        case 'GET_ORDERS_WAITER_APP_FAILURE':
            return {
                ...state,
                ordersLoading: false,
            }
        // GET_ORDERS_WAITER_APP_END

        // CLEAR_ORDERS_WAITER_APP_START
        case 'CLEAR_ORDERS_WAITER_APP':
            return {
                ...state,
                orders: [],
                ordersLoading: false,
            }
        // CLEAR_ORDERS_WAITER_APP_END

        // GET_ORDER_WAITER_APP_START
        case 'GET_ORDER_WAITER_APP':
            return {
                ...state,
                order: action.payload,
            }
        // GET_ORDER_WAITER_APP_END

        // CLEAR_ORDER_WAITER_APP_START
        case 'CLEAR_ORDER_WAITER_APP':
            return {
                ...state,
                order: null,
            }
        // CLEAR_ORDER_WAITER_APP_END

        // ADD_ORDER_WAITER_APP_START
        case 'ADD_ORDER_WAITER_APP': {
            const { orders } = state

            const orderIndex = orders.findIndex((order) => order.status === 'New')

            if (orders[orderIndex]) {
                orders[orderIndex].grand_total = orders[orderIndex].grand_total + action.payload.grand_total
                orders[orderIndex].grand_quantity = orders[orderIndex].grand_quantity + action.payload.grand_quantity
                
                const productIndex = orders[orderIndex].products.findIndex((product) => product.product_id === action.payload.products[0].product_id)

                if (orders[orderIndex].products[productIndex]) {
                    orders[orderIndex].products[productIndex].quantity = orders[orderIndex].products[productIndex].quantity + action.payload.grand_quantity
                } else {
                    orders[orderIndex].products = [...orders[orderIndex].products, ...action.payload.products]
                }

                return {
                    ...state,
                    orders: orders,
                }
            } else {
                return {
                    ...state,
                    orders: [action.payload, ...orders],
                }
            }
        }
        // ADD_ORDER_WAITER_APP_END

        // REMOVE_ORDER_WAITER_APP_START
        case 'REMOVE_ORDER_WAITER_APP': {
            const { orders } = state

            const filteredOrders = orders.filter((order) => order.id !== action.payload.order.id)

            return {
                ...state,
                orders: filteredOrders,
                order: null,
            }
        }
        // REMOVE_ORDER_WAITER_APP_END

        // UPDATE_ORDER_WAITER_APP_START
        case 'UPDATE_ORDER_WAITER_APP':
            return {
                ...state,
                order: { ...action.payload },
            }
        // UPDATE_ORDER_WAITER_APP_END

        // CREATE_ORDER_WAITER_APP_START
        case 'CREATE_ORDER_WAITER_APP_REQUEST':
            return {
                ...state,
                createOrderLoading: true,
            }
        case 'CREATE_ORDER_WAITER_APP_SUCCESS': {
            const { order } = state

            order.status = 'Placed'
            order.products[0].quantity = action.payload.order.products[0].quantity

            return {
                ...state,
                orders: action.payload.orders,
                order: { ...order },
                createOrderLoading: false,
            }
        }
        case 'CREATE_ORDER_WAITER_APP_FAILURE':
            return {
                ...state,
                createOrderLoading: false,
            }
        // CREATE_ORDER_WAITER_APP_END

        // ORDER_APPROVED_WAITER_APP_START
        case 'ORDER_APPROVED_WAITER_APP': {
            const { order } = state
            
            if (
                order
                && order.uuid === action.payload.order.order_uuid
            ) {
                order.status = 'Accepted'
            }

            return {
                ...state,
                orders: action.payload.orders,
                order: { ...order },
            }
        }
        // ORDER_APPROVED_WAITER_APP_END

        // ORDER_DISAPPROVED_WAITER_APP_START
        case 'ORDER_DISAPPROVED_WAITER_APP': {
            const { order } = state
            
            if (
                order
                && order.uuid === action.payload.order.order_uuid
            ) {
                order.status = 'Declined'
            }

            return {
                ...state,
                orders: action.payload.orders,
                order: { ...order },
            }
        }
        // ORDER_DISAPPROVED_WAITER_APP_END

        // BILL_REQUEST_APPROVED_WAITER_APP_START
        case 'BILL_REQUEST_APPROVED_WAITER_APP':
            return {
                ...state,
                orders: action.payload,
            }
        // BILL_REQUEST_APPROVED_WAITER_APP_END

        default:
            return state
    }
}

export default orders
