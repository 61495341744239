import {
	toast,
	Slide,
} from 'react-toastify'

import store from 'store'

import {
	orderApprovedAction,
	orderDisapprovedAction,
	billRequestApprovedAction,
} from 'store/actions/waiterApp/orders'

import { getItem } from 'utils/storage'

const subscribeOnChannelsWaiterApp = (socket) => {
	const currentOutlet = store.getState().session.currentOutlet
	const currentTable = store.getState().session.currentTable

	if (currentOutlet && currentTable) {
		const channel = socket.channel(`outlet.${currentOutlet.slug}`)

		channel.listen('.ORDER_APPROVED', (res) => {
			console.log(res)

			const orders = getItem('sqr_neo_orders')

			if (orders && res.table_id === parseInt(currentTable.id, 10)) {
				res.order.order_uuid = res.order.uuid

				const orderIndex = orders.findIndex((order) => order.uuid === res.order.order_uuid)
				
				if (orders[orderIndex]) {
					toast.success(`Order ${orders[orderIndex].id} - Accepted`, {
						position: 'top-right',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: false,
						draggable: true,
						progress: undefined,
						transition: Slide,
					})
				}

				playNotificationSound()

				store.dispatch(orderApprovedAction(res.order))
			}
		})

		channel.listen('.ORDER_UNAPPROVED', (res) => {
			console.log(res)

			const orders = getItem('sqr_neo_orders')

			if (orders && res.table_id === parseInt(currentTable.id, 10)) {
				res.order.order_uuid = res.order.uuid

				const orderIndex = orders.findIndex((order) => order.uuid === res.order.order_uuid)
				
				if (orders[orderIndex]) {
					toast.success(`Order ${orders[orderIndex].id} - Declined`, {
						position: 'top-right',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: false,
						draggable: true,
						progress: undefined,
						transition: Slide,
					})
				}

				playNotificationSound()

				store.dispatch(orderDisapprovedAction(res.order))
			}
		})

		channel.listen('.CALLED_BILL_APPROVED', (res) => {
			console.log(res)

			if (res.table_id === parseInt(currentTable.id, 10)) {
				toast.info(`Your bill is on the way`, {
					position: 'top-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: undefined,
					transition: Slide,
				})

				if (
					window.location.hash
					&& window.location.hash.split('/').indexOf('orders') !== -1
					&& window.location.hash.split('/').length - 1 > window.location.hash.split('/').indexOf('orders')
				) {
					window.history.back()
				}

				playNotificationSound()

				store.dispatch(billRequestApprovedAction())
			}
		})
	}
}

const unsubscribeOnChannelsWaiterApp = (socket) => {
	const currentOutlet = store.getState().session.currentOutlet
	const currentTable = store.getState().session.currentTable

	if (currentOutlet && currentTable) {
		socket.leaveChannel(`outlet.${currentOutlet.slug}`)
	}
}

function playNotificationSound() {
	const audioElement = document.createElement('audio')

	audioElement.src = '/sounds/notification.mp3'

	document.body.appendChild(audioElement)

	// if (navigator && navigator.vibrate) {
	// 	navigator.vibrate([500, 250, 500, 250, 500, 250, 500, 250, 500, 250, 500])
	// }

	const audioEnded = () => {
		audioElement.removeEventListener('ended', audioEnded)

		document.body.removeChild(audioElement)
	}

	audioElement.addEventListener('ended', audioEnded)

	audioElement.play()
}

export {
	subscribeOnChannelsWaiterApp,
	unsubscribeOnChannelsWaiterApp,
}
