import React from 'react'

import { AppButton } from 'components'

import { translate } from 'utils/helpers'

import styles from './MasterCategoriesListItem.module.scss'

const MasterCategoriesListItem = (props) => {
    const {
        item,
        selectedMasterCategory,
        onSelectMasterCategory,
    } = props

    const getClassName = () => {
        if (selectedMasterCategory === item) return styles['container-active']

        return styles.container
    }

    return (
        <AppButton
            className={getClassName()}
            onClick={() => onSelectMasterCategory(item)}
        >
            {translate(item.name)}
        </AppButton>
    )
}

export default MasterCategoriesListItem
