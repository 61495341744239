import * as React from 'react'

function SvgComponent(props) {
    return (
        <svg
            width={44}
            height={40}
            viewBox="0 0 44 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect
                x={0.5}
                y={0.5}
                width={43}
                height={39}
                rx={3.5}
                fill="#fff"
                stroke="#2868C8"
            />
            <path
                d="M.5 4A3.5 3.5 0 014 .5h36A3.5 3.5 0 0143.5 4v1.654H.5V4z"
                fill="#D8E7FE"
                stroke="#2868C8"
            />
            <rect
                x={22.25}
                y={0.25}
                width={0.5}
                height={39.5}
                rx={0.25}
                stroke="#2868C8"
                strokeWidth={0.5}
            />
            <path
                stroke="#2868C8"
                strokeLinecap="round"
                d="M25.5 12.833h8M36.5 12.833h3M25.5 18.987h8M36.5 18.987h3M25.5 28.218h8M36.5 28.218h3M25.5 15.91h8M36.5 15.91h3M25.5 25.141h8M36.5 25.141h3M25.5 22.064h8M36.5 22.064h3M25.5 31.295h8M36.5 31.295h3"
            />
            <rect
                x={3.5}
                y={18.962}
                width={15}
                height={2.077}
                rx={1.038}
                fill="#D8E7FE"
                stroke="#2868C8"
            />
            <path
                d="M4.525 18.987h-.007c.249-3.465 3.067-6.18 6.482-6.18s6.233 2.715 6.482 6.18H4.525z"
                fill="#D8E7FE"
                stroke="#2868C8"
            />
            <path
                d="M11.5 12.308a.513.513 0 01-.5.525.513.513 0 01-.5-.525c0-.303.236-.526.5-.526s.5.223.5.526z"
                stroke="#2868C8"
            />
            <path
                stroke="#2868C8"
                strokeLinecap="round"
                d="M3.5 25.141h15M3.5 28.218h15M3.5 31.295h15"
            />
        </svg>
    )
}

const MemoSvgComponent = React.memo(SvgComponent)

export default MemoSvgComponent
