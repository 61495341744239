import * as React from 'react'

function SvgComponent(props) {
	return (
		<svg
			width={45}
			height={43}
			viewBox="0 0 45 43"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M9.158 41.92c.8.625 1.817.41 3.027-.469l10.332-7.598 10.352 7.598c1.211.88 2.207 1.094 3.027.469.801-.605.977-1.602.489-3.027l-4.082-12.149 10.43-7.5c1.21-.86 1.699-1.758 1.386-2.734-.312-.938-1.23-1.407-2.734-1.387l-12.793.078-3.887-12.207C24.236 1.55 23.533.826 22.517.826c-.996 0-1.699.723-2.168 2.168l-3.886 12.207-12.793-.078c-1.504-.02-2.422.45-2.735 1.387-.332.976.176 1.875 1.387 2.734l10.43 7.5L8.67 38.893c-.488 1.425-.313 2.422.488 3.027z"
				fill="#A1BCE4"
			/>
		</svg>
	)
}

const MemoSvgComponent = React.memo(SvgComponent)

export default MemoSvgComponent
