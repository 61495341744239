import React, { memo } from 'react'

import Picker from 'emoji-picker-react'

import styles from './AppEmojiPicker.module.scss'

const AppEmojiPicker = (props) => {
    const {
        height,
        onEmojiClick,
    } = props

    return (
        <div className={styles.container}>
            <Picker
                disableSearchBar
                preload
                pickerStyle={{
                    width: '100%',
                    height: height,
                }}
                onEmojiClick={onEmojiClick}
            />
        </div>
    )
}

export default memo(AppEmojiPicker)
