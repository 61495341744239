import axios from 'axios'

import store from 'store'

import {
    isArray,
    isObject,
    isString,
} from 'utils/types'

let axiosInstance = null

function updateBaseURL() {
    axiosInstance = axios.create({ baseURL: store.getState().session.BASE_URL })

    axiosInstance.interceptors.request.use(handleSuccess, null)
    axiosInstance.interceptors.response.use(null, handleError)
}

function handleSuccess(res) {
    return res
}

function handleError(error) {
    if (error) {
        let message = 'Ooops!'
        let description = 'Something went wrong!'

        if (error.response && error.response.data) {
            if (error.response.data.error && isString(error.response.data.error)) {
                description = error.response.data.error
            } else if (error.response.data.message && isString(error.response.data.message)) {
                description = error.response.data.message
            }

            if (error.response.data.errors) {
                const errors = []

                if (isObject(error.response.data.errors)) {
                    Object.keys(error.response.data.errors).forEach((key) => {
                        if (isArray(error.response.data.errors[key])) {
                            error.response.data.errors[key].forEach((error) => {
                                if (isString(error)) {
                                    errors.push(error)
                                }
                            })
                        }
                    })
                }

                if (errors.length) {
                    description = errors.join('\n')
                }
            }
        }
    }

    console.log(error)

    return Promise.reject(error)
}

export {
    axiosInstance,
    updateBaseURL,
}
