import React from 'react'

import { getDate } from 'utils/helpers'

import { MessageReadIcon } from 'assets/icons'

import styles from './ChatListItem.module.scss'

const ChatListItem = (props) => {
    const {
        data,
        index,
        item,
    } = props

    const getMessageStyles = () => {
        const style = {
            marginBottom: 32,
        }

        if (
            data[index + 1]
            && data[index + 1].type === item.type
        ) {
            style.marginBottom = 16
        }

        return style
    }

    const renderMessage = () => {
        if (item.type === 'client') {
            return (
                <div
                    className={styles.outgoingMessageContainer}
                    style={{ marginBottom: getMessageStyles().marginBottom }}
                >
                    <div className={styles.outgoingMessage}>
                        <p className={styles.outgoingMessageText}>{item.message}</p>
                    
                        <p className={styles.outgoingMessageDate}>{getDate(item.date)} ago</p>
                    </div>

                    {item.read && <MessageReadIcon className={styles.messageReadIcon} />}
                </div>
            )
        }

        return (
            <p
                className={styles.incomingMessage}
                style={{ marginBottom: getMessageStyles().marginBottom }}
            >
                {item.message}
            </p>
        )
    }

    return (
        <div
            style={{
                alignSelf: item.type === 'client'
                    ? 'flex-end'
                    : 'flex-start'
            }}
        >
            {renderMessage()}
        </div>
    )
}

export default ChatListItem
