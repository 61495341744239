import React, { useState } from 'react'

import { NavLink } from 'react-router-dom'

import { AppButton } from 'components'

import {
    MinusIcon,
    PlusIcon,
} from 'assets/icons'

import {
    translate,
    getCurrency,
} from 'utils/helpers'

import styles from './MenuListItem.module.scss'

const MenuListItem = (props) => {
    const {
        item,
        currentRoom,
        currentOutlet,
        onSelectProduct,
        onAddToOrder,
    } = props

    const [isImageLoaded, setIsImageLoaded] = useState(false)
    
    const [quantity, setQuantity] = useState(1)

    const onChangeQuantity = (value) => {
        const newQuantity = quantity + value

        if (newQuantity > 0) setQuantity(newQuantity)
    }

    const renderHeader = () => {
        if (isImageLoaded) {
            return (
                <div className={styles.header}>
                    <p className={styles.header_category}>{translate(item.categories[0].name)}</p>
    
                    <p className={styles.header_title}>{translate(item.name)}</p>
    
                    <div className={styles.header_description}>
                        {item.price && getCurrency(item.price) && <p>{getCurrency(item.price)}</p>}
    
                        {item.weight && <p>{item.weight} g</p>}
                    </div>
                </div>
            )
        }

        return null
    }

    const renderFooter = () => {
        if (isImageLoaded && currentRoom && currentOutlet.slug === currentRoom.slug) {
            return (
                <div
                    className={styles.footer}
                    onClick={(event) => event.preventDefault()}
                >
                    <div className={styles['footer_buttons-quantity']}>
                        <AppButton
                            className={styles['footer_buttons-quantity_decrease']}
                            onClick={() => onChangeQuantity(-1)}
                        >
                            <MinusIcon color='#FFFFFF' />
                        </AppButton>
    
                        <p className={styles.footer_quantity}>{quantity}</p>
    
                        <AppButton
                            className={styles['footer_buttons-quantity_increase']}
                            onClick={() => onChangeQuantity(1)}
                        >
                            <PlusIcon color='#FFFFFF' />
                        </AppButton>
                    </div>
    
                    <AppButton
                        className={styles['footer_button-confirm']}
                        onClick={() => onAddToOrder(item, quantity)}
                    >
                        Add to order
                    </AppButton>
                </div>
            )
        }

        return null
    }

    return (
        <NavLink
            className={styles.container}
            to={onSelectProduct(item)}
        >
            {renderHeader()}

            <img
                className={styles.image}
                src={item.images[0]}
                alt={translate(item.name)}
                onLoad={() => setIsImageLoaded(true)}
            />

            {renderFooter()}
        </NavLink>
    )
}

export default MenuListItem
