import FeaturesService from 'services/hotelApp/features'

function callRoomServiceAction(params = {}, callback) {
    return async (dispatch) => {
        dispatch({ type: 'CALL_ROOM_SERVICE_HOTEL_APP_REQUEST' })

        try {
            await FeaturesService.callRoomService(params)

            dispatch({ type: 'CALL_ROOM_SERVICE_HOTEL_APP_SUCCESS' })

            if (callback) callback()
        } catch (error) {
            dispatch({ type: 'CALL_ROOM_SERVICE_HOTEL_APP_FAILURE' })
        }
    }
}

function leaveFeedbackAction(params = {}, callback) {
    return async (dispatch) => {
        dispatch({ type: 'LEAVE_FEEDBACK_HOTEL_APP_REQUEST' })

        try {
            await FeaturesService.leaveFeedback(params)

            dispatch({ type: 'LEAVE_FEEDBACK_HOTEL_APP_SUCCESS' })

            if (callback) callback()
        } catch (error) {
            dispatch({ type: 'LEAVE_FEEDBACK_HOTEL_APP_FAILURE' })
        }
    }
}

export {
    callRoomServiceAction,
    leaveFeedbackAction,
}
