import React from 'react'

import {
    AppModal,
    AppButton,
} from 'components'

import styles from './ModalConfirm.module.scss'

const ModalConfirm = (props) => {
    const {
        title,
        subtitle,
        onClose,
    } = props

    return (
        <AppModal
            classNameOverlay={styles.overlay}
            classNameModal={styles.modal}
            onClose={onClose}
        >
            <div className={styles.icon} />

            <p className={styles.title}>{title}</p>

            <p className={styles.subtitle}>{subtitle}</p>

            <AppButton
                className={styles.button}
                onClick={onClose}
            >
                Continue
            </AppButton>
        </AppModal>
    )
}

export default ModalConfirm
