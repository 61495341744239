import React from 'react'

import IngredientsListItem from '../IngredientsListItem'

const IngredientsList = (props) => {
    const {
        data,
        selectedIngredients,
        onChangeSelectedIngredients,
    } = props
   
    const renderList = () => {
        if (!!data.length) {
            return (
                <div>
                    {
                        data.map((item, index) => (
                            <IngredientsListItem
                                key={index}
                                item={item}
                                selectedIngredients={selectedIngredients}
                                onChangeSelectedIngredients={onChangeSelectedIngredients}
                            />
                        ))
                    }
                </div>
            )
        }

        return null
    }

    return renderList()
}

export default IngredientsList
