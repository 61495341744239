import React, {
    useState,
    useEffect,
} from 'react'

import {
    useDispatch,
    useSelector,
} from 'react-redux'

import {
    getOrderAction,
    clearOrderAction,
    createOrderAction,
    updateOrderAction,
    removeOrderAction,
} from 'store/actions/waiterApp/orders'

import {
    AppContainer,
    AppHeader,
} from 'components'

import {
    OrderStatus,
    OrderList,
    ModalConfirm,
} from 'components/WaiterApp'

import { getCurrency } from 'utils/helpers'

import styles from './Order.module.scss'

const Order = (props) => {
    const {
        history,
        match: { params },
    } = props

    const [isModalVisible, setIsModalVisible] = useState(false)

    const currentTable = useSelector((state) => state.session.currentTable)

    const order = useSelector((state) => state.waiterAppOrders.order)

    const createOrderLoading = useSelector((state) => state.waiterAppOrders.createOrderLoading)

    const dispatch = useDispatch()

    useEffect(() => {
        window.scrollTo(0, 0)

        const getOrderParams = {
            id: params.id,
        }

        dispatch(getOrderAction(getOrderParams))

        return () => {
            dispatch(clearOrderAction())
        }
    }, [])

    const onPlaceOrder = () => {
        const createOrderParams = {
            order: order,
        }

        dispatch(createOrderAction(createOrderParams, () => {
            setIsModalVisible(true)
        }))
    }

    const onUpdateOrder = (product) => {
        const updateOrderParams = {
            order: {
                ...order,
                grand_total: order.grand_total - product.product[0].price,
                grand_quantity: order.grand_quantity - 1,
                products: order.products.filter((filteredProduct) => {
                    if (
                        filteredProduct.product_id === product.product_id
                    ) {
                        if (filteredProduct.quantity > 1) {
                            filteredProduct.quantity = filteredProduct.quantity - 1

                            return true
                        }
                    } else {
                        return true
                    }
                }),
            },
        }
        
        if (updateOrderParams.order.grand_quantity > 0) {
            dispatch(updateOrderAction(updateOrderParams))
        } else {
            dispatch(removeOrderAction(updateOrderParams, () => history.goBack()))
        }
    }

    const getOrderListData = () => {
        const data = []

        if (order && !!order.products.length) {
            order.products.map((product) => {
                for (let i = 0; i < product.quantity; i++) {
                    data.push({
                        ...order,
                        products: [product],
                    })
                }
            })
        }

        return data
    }

    const renderModal = () => {
        if (isModalVisible) {
            return (
                <ModalConfirm
                    title="Your order is successful"
                    subtitle="Please check your order status on the orders page"
                    onClose={() => setIsModalVisible(false)}
                />
            )
        }

        return null
    }

    return (
        <AppContainer>
            <div style={{ position: 'fixed', width: '100%', backgroundColor: '#FFFFFF' }}>
                <AppHeader
                    type="back"
                    className={styles.header}
                    onClose={() => history.goBack()}
                />

                <AppHeader
                    type="title"
                    className={styles.header}
                    title={`Order ${params.id}`}
                    subtitle={`Table ${currentTable.id}`}
                />

                {
                    order && (
                        <OrderStatus
                            status={order.status}
                            price={getCurrency(order.grand_total)}
                            loading={createOrderLoading}
                            onClick={() => onPlaceOrder()}
                        />
                    )
                }
            </div>
            
            {
                order && (
                    <div style={{ paddingTop: order.status === 'New' ? 347 : 278 }}>
                        <OrderList
                            data={getOrderListData()}
                            onUpdateOrder={onUpdateOrder}
                        />
                    </div>
                )
            }

            {renderModal()}
        </AppContainer>
    )
}

export default Order
