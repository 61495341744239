import Echo from 'laravel-echo'

import {
	subscribeOnChannelsHotelApp,
	unsubscribeOnChannelsHotelApp,
} from './hotelApp/socket'

import {
	subscribeOnChannelsWaiterApp,
	unsubscribeOnChannelsWaiterApp,
} from './waiterApp/socket'

window.io = require('socket.io-client')

const subscribeOnChannels = (currentService) => {
	if (currentService) {
		if (currentService === 'hotelApp') {
			window.Echo = new Echo({
				broadcaster: 'socket.io',
				host: 'https://sqrroom.sqrneo.com:6001',
				transports: ['websocket'],
			})

			subscribeOnChannelsHotelApp(window.Echo)
		} else if (currentService === 'waiterApp') {
			window.Echo = new Echo({
				broadcaster: 'socket.io',
				host: 'https://sqrmenu.sqrneo.com:6001',
				transports: ['websocket'],
			})

			subscribeOnChannelsWaiterApp(window.Echo)
		}
	}
}

const unsubscribeOnChannels = (currentService) => {
	if (currentService) {
		if (currentService === 'hotelApp') {
			unsubscribeOnChannelsHotelApp(window.Echo)
		} else if (currentService === 'waiterApp') {
			unsubscribeOnChannelsWaiterApp(window.Echo)
		}
	}
}

export {
	subscribeOnChannels,
	unsubscribeOnChannels,
}
