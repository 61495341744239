import * as React from 'react'

function SvgComponent(props) {
	return (
		<svg
			width={16}
			height={19}
			viewBox="0 0 16 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M9.604 13.125H8.76V1.312c0-.28-.281-.562-.563-.562h-.562a.578.578 0 00-.563.563v11.812H6.23a.578.578 0 00-.563.563v1.124c0 .317.246.563.563.563h.843v2.813c0 .316.246.562.563.562h.562a.578.578 0 00.563-.563v-2.812h.844a.578.578 0 00.562-.563v-1.124c0-.282-.281-.563-.562-.563zm-5.625-9h-.844V1.312c0-.28-.281-.562-.563-.562H2.01a.578.578 0 00-.563.563v2.812H.604a.578.578 0 00-.563.563v1.125c0 .316.246.562.563.562h.843v11.813c0 .316.246.562.563.562h.562a.578.578 0 00.563-.563V6.376h.844a.578.578 0 00.562-.563V4.688c0-.28-.281-.562-.562-.562zm11.25 4.5h-.844V1.312c0-.28-.281-.562-.563-.562h-.562a.578.578 0 00-.563.563v7.312h-.843a.578.578 0 00-.563.563v1.124c0 .317.246.563.563.563h.843v7.313c0 .316.246.562.563.562h.562a.578.578 0 00.563-.563v-7.312h.844a.578.578 0 00.562-.563V9.188c0-.282-.281-.563-.563-.563z"
				fill="#2868C8"
			/>
		</svg>
	)
}

const MemoSvgComponent = React.memo(SvgComponent)

export default MemoSvgComponent
