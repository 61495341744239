import { axiosInstance } from '../instance'

function callRoomService(params) {
	return axiosInstance.post('calls/room_service', params)
}

function leaveFeedback(params) {
	return axiosInstance.post('review/add', params)
}

export default {
    callRoomService,
    leaveFeedback,
}
