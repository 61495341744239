import CategoriesService from 'services/waiterApp/categories'

function getCategoriesAction(params = {}, callback) {
    return async (dispatch) => {
        dispatch({ type: 'GET_CATEGORIES_WAITER_APP_REQUEST' })

        try {
            let data = []
            
            if (params.id === 'all') {
                data = await CategoriesService.getCategories()
            } else {
                data = { data: { data: params.data } }
            }

            const categories = [
                {
                    id: 'all',
                    name: {
                        en: 'All',
                        ru: 'Все',
                    },
                },
                ...data.data.data
            ]

            dispatch({
                type: 'GET_CATEGORIES_WAITER_APP_SUCCESS',
                payload: categories,
            })

            if (callback) callback()
        } catch (error) {
            dispatch({ type: 'GET_CATEGORIES_WAITER_APP_FAILURE' })
        }
    }
}

function clearCategoriesAction(params = {}, callback) {
    return (dispatch) => {
        dispatch({ type: 'CLEAR_CATEGORIES_WAITER_APP' })
    
        if (callback) callback()
    }
}

function getMasterCategoriesAction(params = {}, callback) {
    return async (dispatch) => {
        dispatch({ type: 'GET_MASTER_CATEGORIES_WAITER_APP_REQUEST' })

        try {
            const data = await CategoriesService.getMasterCategories()

            const masterCategories = data.data.data

            dispatch({
                type: 'GET_MASTER_CATEGORIES_WAITER_APP_SUCCESS',
                payload: masterCategories,
            })

            if (callback) callback(masterCategories)
        } catch (error) {
            dispatch({ type: 'GET_MASTER_CATEGORIES_WAITER_APP_FAILURE' })
        }
    }
}

function clearMasterCategoriesAction(params = {}, callback) {
    return (dispatch) => {
        dispatch({ type: 'CLEAR_MASTER_CATEGORIES_WAITER_APP' })
    
        if (callback) callback()
    }
}

export {
    getCategoriesAction,
    clearCategoriesAction,

    getMasterCategoriesAction,
    clearMasterCategoriesAction,
}
