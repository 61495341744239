import * as React from 'react'

function SvgComponent(props) {
	return (
		<svg
			width={36}
			height={36}
			viewBox="0 0 36 36"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M14.341 10.74h-4c-.292 0-.5.264-.5.577v3.846c0 .337.208.577.5.577h4c.27 0 .5-.24.5-.577v-3.846c0-.313-.23-.577-.5-.577zM24.141 10.74h-4c-.292 0-.5.264-.5.577v3.846c0 .337.208.577.5.577h4c.27 0 .5-.24.5-.577v-3.846c0-.313-.23-.577-.5-.577zM24.141 20.98h-4c-.292 0-.5.264-.5.577v3.846c0 .337.208.577.5.577h4c.27 0 .5-.24.5-.577v-3.846c0-.313-.23-.577-.5-.577zM14.341 20.98h-4c-.292 0-.5.264-.5.577v3.846c0 .337.208.577.5.577h4c.27 0 .5-.24.5-.577v-3.846c0-.313-.23-.577-.5-.577z"
				fill="#24242E"
			/>

			<rect
				opacity={0.2}
				x={0.541}
				y={1}
				width={34}
				height={34}
				rx={6.5}
				stroke="#9CA7B5"
			/>
		</svg>
	)
}

const MemoSvgComponent = React.memo(SvgComponent)

export default MemoSvgComponent
