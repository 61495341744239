function getItem(key) {
	const jsonValue = localStorage.getItem(key)

	if (!jsonValue) return null

	const item = JSON.parse(jsonValue)

	return item
}

function setItem(key, value) {
	localStorage.setItem(key, JSON.stringify(value))
}

function removeItem(key) {
	const jsonValue = localStorage.getItem(key)

	if (!jsonValue) return null

	localStorage.removeItem(key)
}

export {
    getItem,
    setItem,
    removeItem,
}
