import React from 'react'

import { NavLink } from 'react-router-dom'

import { useSelector } from 'react-redux'

import AppIndicator from '../AppIndicator'

import {
    MenuIcon,
    OrdersIcon,
    FeaturesIcon,
} from 'assets/icons'

import styles from './AppBottomTabbar.module.scss'

const defaultTabs = [
    { route: '/menu', title: 'Menu', icon: MenuIcon },
    { route: '/orders', title: 'Orders', icon: OrdersIcon },
]

const tabs = [
    { route: '/menu', title: 'Menu', icon: MenuIcon },
    { route: '/orders', title: 'Orders', icon: OrdersIcon },
    { route: '/features', title: 'Features', icon: FeaturesIcon }
]

const AppBottomTabbar = (props) => {
    const {
        location,
        currentOutlet,
    } = props

    const orders = useSelector((state) => state[`${currentOutlet.service}Orders`].orders)

    const renderOrdersIndicator = (tab) => {
        if (
            tab.title === 'Orders'
            && !!orders.length
        ) {
            const newOrders = orders.filter((order) => order.status === 'New')

            if (!!newOrders.length) {
                return <AppIndicator className={styles.indicator} />
            }
        }

        return null
    }

    const renderTab = (tab, index) => {
        const isActive = location && location.pathname && location.pathname.indexOf(tab.route) !== -1

        return (
            <NavLink
                key={index}
                className={styles.tab}
                to={tab.route}
            >
                <tab.icon color={ isActive ? '#2868C8' : '#9CA7B5'} />

                {renderOrdersIndicator(tab)}
            </NavLink>
        )
    }

    const renderBottomTabbar = () => {
        if (currentOutlet?.upgrades) {
            return tabs.map((tab, index) => renderTab(tab, index))
        }

        return defaultTabs.map((tab, index) => renderTab(tab, index))
    }

    return (
        <div className={styles.container}>
            {renderBottomTabbar()}
        </div>
    )
}

export default AppBottomTabbar
