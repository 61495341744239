import React, { useState } from 'react'

import {
    useSelector,
    useDispatch,
} from 'react-redux'

import { updateServiceAction } from 'store/actions/session'

import {
    AppContainer,
    AppImage,
    AppButton,
    AppModal,
    ServicesList,
} from 'components'

import {
    ServiceLeisureIcon,
    ServiceMenuIcon,
    ServiceRoomsIcon,
} from 'assets/icons'

import styles from './Home.module.scss'

const services = {
    leisureApp: {
        icon: <ServiceLeisureIcon />,
    },
    waiterApp: {
        icon: <ServiceMenuIcon />,
    },
    hotelApp: {
        icon: <ServiceRoomsIcon />,
    },
}

const Home = (props) => {
    const {
        history,
    } = props

    const [isBackgroundLoaded, setIsBackgroundLoaded] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false)

    const clientOutlet = useSelector((state) => state.session.clientOutlet)

    const dispatch = useDispatch()

    const onSelectService = (outlet) => {
        const updateServiceParams = {
            outlet: outlet,
        }

        window.location.href = outlet.domain

        // dispatch(updateServiceAction(updateServiceParams, () => {
        //     history.push({ pathname: '/menu' })
        // }))
    }

    const onLetsStartButtonClick = () => {
        if (clientOutlet.services && clientOutlet.services.length === 1) {
            onSelectService(clientOutlet.services[0])
        } else {
            setIsModalVisible(true)
        }
    }

    const renderModal = () => {
        if (isModalVisible) {
            return (
                <AppModal
                    classNameOverlay={styles.overlay}
                    classNameModal={styles.modal}
                    onClose={() => setIsModalVisible(false)}
                >
                    <p className={styles['modal_title-client']}>{clientOutlet.name}</p>
                    <p className={styles.modal_subtitle}>Choose a service</p>
               
                    <ServicesList
                        data={clientOutlet.services}
                        services={services}
                        onSelectService={onSelectService}
                    />
                </AppModal>
            )
        }

        return null
    }

    return (
        <AppContainer className={styles.container}>
            {
                clientOutlet.background_image && (
                    <AppImage
                        loading
                        src={clientOutlet.background_image}
                        containerClassName={styles.backgroundContainer}
                        imageClassName={styles.backgroundImage}
                        onImageLoad={() => setIsBackgroundLoaded(true)}
                    />
                )
            }

            {
                clientOutlet.logo_image && (
                    <AppImage
                        src={clientOutlet.logo_image}
                        containerClassName={styles.logoContainer}
                        imageClassName={styles.logoImage}
                    />
                )
            }

            {
                clientOutlet.background_image
                ? (
                    <div
                        className={styles.footer}
                        style={{ opacity: isBackgroundLoaded ? 1 : 0 }}
                    >
                        <p className={styles.footer_title}>
                            Welcome to
                            <span className={styles['footer_title-client']}>{` ${clientOutlet.name}`}</span>
                        </p>

                        <p className={styles.footer_subtitle}>Penthouses & Apartments On Iconic Palm in Dubai</p>

                        <AppButton
                            className={`${styles.footer_button} ${styles['footer_button-continue']}`}
                            onClick={() => onLetsStartButtonClick()}
                        >
                            Let's start
                        </AppButton>
                    </div>
                ) : (
                    <div
                        className={styles.footer}
                        style={{ opacity: 1 }}
                    >
                        <p className={styles.footer_title}>
                            Welcome to
                            <span className={styles['footer_title-client']}>{` ${clientOutlet.name}`}</span>
                        </p>

                        <p className={styles.footer_subtitle}>Penthouses & Apartments On Iconic Palm in Dubai</p>

                        <AppButton
                            className={`${styles.footer_button} ${styles['footer_button-continue']}`}
                            onClick={() => onLetsStartButtonClick()}
                        >
                            Let's start
                        </AppButton>
                    </div>
                )
            }

            {renderModal()}
        </AppContainer>
    )
}

export default Home
