const initialState = {
    callRoomServiceLoading: false,
    leaveFeedbackLoading: false,
}

const features = (state = initialState, action) => {
    switch(action.type) {
        // CALL_ROOM_SERVICE_HOTEL_APP_START
        case 'CALL_ROOM_SERVICE_HOTEL_APP_REQUEST':
            return {
                ...state,
                callRoomServiceLoading: true,
            }
        case 'CALL_ROOM_SERVICE_HOTEL_APP_SUCCESS':
            return {
                ...state,
                callRoomServiceLoading: false,
            }
        case 'CALL_ROOM_SERVICE_HOTEL_APP_FAILURE':
            return {
                ...state,
                callRoomServiceLoading: false,
            }
        // CALL_ROOM_SERVICE_HOTEL_APP_END

        // LEAVE_FEEDBACK_HOTEL_APP_START
        case 'LEAVE_FEEDBACK_HOTEL_APP_REQUEST':
            return {
                ...state,
                leaveFeedbackLoading: true,
            }
        case 'LEAVE_FEEDBACK_HOTEL_APP_SUCCESS':
            return {
                ...state,
                leaveFeedbackLoading: false,
            }
        case 'LEAVE_FEEDBACK_HOTEL_APP_FAILURE':
            return {
                ...state,
                leaveFeedbackLoading: false,
            }
        // LEAVE_FEEDBACK_HOTEL_APP_END

        default:
            return state
    }
}

export default features
