import React from 'react'

import { NavLink } from 'react-router-dom'

import styles from './FeaturesListItem.module.scss'

const FeaturesListItem = (props) => {
    const {
        item,
    } = props

    return (
        <NavLink
            className={styles.container}
            to={`/features/${item.route}`}
        >
            {item.icon}
    
            <p className={styles.title}>{item.title}</p>
        </NavLink>
    )
}

export default FeaturesListItem
