import OrdersService from 'services/hotelApp/orders'

import {
    getItem,
    setItem,
    removeItem,
} from 'utils/storage'

function getOrdersAction(params = {}, callback) {
    return async (dispatch) => {
        dispatch({ type: 'GET_ORDERS_HOTEL_APP_REQUEST' })

        try {
            const orders = getItem('sqr_neo_orders')

            if (orders) {
                dispatch({
                    type: 'GET_ORDERS_HOTEL_APP_SUCCESS',
                    payload: orders,
                }) 
            } else {
                dispatch({
                    type: 'GET_ORDERS_HOTEL_APP_SUCCESS',
                    payload: [],
                })
            }

            if (callback) callback()
        } catch (error) {
            dispatch({ type: 'GET_ORDERS_HOTEL_APP_FAILURE' })
        }
    }
}

function clearOrdersAction(params = {}, callback) {
    return (dispatch) => {
        dispatch({ type: 'CLEAR_ORDERS_HOTEL_APP' })
    
        if (callback) callback()
    }
}

function getOrderAction(params = {}, callback) {
    return (dispatch) => {
        const orders = getItem('sqr_neo_orders')

        const order = orders.find((order) => order.id === params.id)

        if (order) {
            dispatch({
                type: 'GET_ORDER_HOTEL_APP',
                payload: order,
            })
        } else {
            dispatch({
                type: 'GET_ORDER_HOTEL_APP',
                payload: null,
            })
        }

        if (callback) callback()
    }
}

function clearOrderAction(params = {}, callback) {
    return (dispatch) => {
        dispatch({ type: 'CLEAR_ORDER_HOTEL_APP' })
    
        if (callback) callback()
    }
}

function addOrderAction(params = {}, callback) {
    return (dispatch) => {
        const orders = getItem('sqr_neo_orders')

        if (orders) {
            const order = orders.find((order) => order.status === 'New')

            if (order) {
                const filteredOrders = orders.filter((filteredOrder) => filteredOrder.id !== order.id)

                order.grand_total = order.grand_total + params.grand_total
                order.grand_quantity = order.grand_quantity + params.grand_quantity

                const productIndex = order.products.findIndex((product) => product.product_id === params.products[0].product_id)

                if (order.products[productIndex]) {
                    order.products[productIndex].quantity = order.products[productIndex].quantity + params.grand_quantity
                } else {
                    order.products = [...order.products, ...params.products]
                }

                setItem('sqr_neo_orders', [order, ...filteredOrders])
            } else {
                const id = parseInt(orders[0].id, 10) + 1

                params.id = id.toString().padStart(3, 0)
    
                setItem('sqr_neo_orders', [params, ...orders])
            }

            dispatch({
                type: 'ADD_ORDER_HOTEL_APP',
                payload: params,
            })
        } else {
            params.id = '001'

            setItem('sqr_neo_orders', [params])

            dispatch({
                type: 'ADD_ORDER_HOTEL_APP',
                payload: params,
            })
        }

        if (callback) callback()
    }
}

function removeOrderAction(params = {}, callback) {
    return (dispatch) => {
        const orders = getItem('sqr_neo_orders')

        const updatedOrders = orders.filter((order) => order.id !== params.order.id)

        if (!!updatedOrders.length) {
            setItem('sqr_neo_orders', updatedOrders)
        } else {
            removeItem('sqr_neo_orders')
        }

        dispatch({
            type: 'REMOVE_ORDER_HOTEL_APP',
            payload: params,
        })

        if (callback) callback()
    }
}

function updateOrderAction(params = {}, callback) {
    return (dispatch) => {
        dispatch({
            type: 'UPDATE_ORDER_HOTEL_APP',
            payload: params.order,
        })

        if (callback) callback()
    }
}

function createOrderAction(params = {}, callback) {
    return async (dispatch) => {
        dispatch({ type: 'CREATE_ORDER_HOTEL_APP_REQUEST' })

        try {
            const data = await OrdersService.createOrder(params.order)

            const uuid = data.data.data.order.uuid

            params.order.uuid = uuid
    
            const orders = getItem('sqr_neo_orders')

            const orderIndex = orders.findIndex((order) => order.id === params.order.id)

            if (orders[orderIndex]) {
                orders[orderIndex].uuid = uuid
                orders[orderIndex].status = 'Placed'
                orders[orderIndex].products[0].quantity = params.order.products[0].quantity
                orders[orderIndex].grand_total = params.order.grand_total

                setItem('sqr_neo_orders', orders)
            }

            dispatch({
                type: 'CREATE_ORDER_HOTEL_APP_SUCCESS',
                payload: {
                    orders,
                    order: params.order,
                },
            })

            if (callback) callback()
        } catch (error) {
            dispatch({ type: 'CREATE_ORDER_HOTEL_APP_FAILURE' })
        }
    }
}

function orderApprovedAction(params = {}, callback) {
    return (dispatch) => {
        const orders = getItem('sqr_neo_orders')

        const orderIndex = orders.findIndex((order) => order.uuid === params.order_uuid)

        if (orders[orderIndex]) {
            orders[orderIndex].status = 'Accepted'

            setItem('sqr_neo_orders', orders)
        }

        dispatch({
            type: 'ORDER_APPROVED_HOTEL_APP',
            payload: {
                orders,
                order: params,
            },
        })

        if (callback) callback()
    }
}

function orderDisapprovedAction(params = {}, callback) {
    return (dispatch) => {
        const orders = getItem('sqr_neo_orders')

        const orderIndex = orders.findIndex((order) => order.uuid === params.order_uuid)

        if (orders[orderIndex]) {
            orders[orderIndex].status = 'Declined'

            setItem('sqr_neo_orders', orders)
        }

        dispatch({
            type: 'ORDER_DISAPPROVED_HOTEL_APP',
            payload: {
                orders,
                order: params,
            },
        })

        if (callback) callback()
    }
}

export {
    getOrdersAction,
    clearOrdersAction,

    getOrderAction,
    clearOrderAction,

    addOrderAction,
    removeOrderAction,
    updateOrderAction,
    createOrderAction,

    orderApprovedAction,
    orderDisapprovedAction,
}

