import React, {
    useEffect,
    useRef,
} from 'react'

import styles from './AppModal.module.scss'

const AppModal = (props) => {
    const {
        classNameOverlay = '',
        classNameModal = '',
        onClose,
        children
    } = props

    const containerRef = useRef()

    useEffect(() => {
        document.body.style.overflow = 'hidden'

        return () => {
            document.body.style.overflow = 'auto'
        }
    }, [])

    const onOverlayClick = (event) => {
        if (
            containerRef
            && containerRef.current
            && containerRef.current === event.target
        ) {
            onClose()
        }
    }

    return (
        <div
            ref={containerRef}
            className={`${styles.overlay} ${classNameOverlay}`}
            onClick={(event) => onOverlayClick(event)}
        >
            <div className={`${styles.modal} ${classNameModal}`}>
                {children}
            </div>
        </div>
    )
}

export default AppModal
