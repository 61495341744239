import React from 'react'

import styles from './AppContainer.module.scss'

const AppContainer = (props) => {
    const {
        className = '',
        style,
        children,
    } = props

    return (
        <div
            className={`${styles.container} ${className}`}
            style={style}
        >
            {children}
        </div>
    )
}

export default AppContainer
