const initialState = {
    languages: [],
    languagesLoading: false,
}

const languages = (state = initialState, action) => {
    switch(action.type) {
        //GET_LANGUAGES_START
        case 'GET_LANGUAGES_REQUEST':
            return {
                ...state,
                languagesLoading: true,
            }
        case 'GET_LANGUAGES_SUCCESS':
            return {
                ...state,
                languages: action.payload,
                languagesLoading: false,
            }
        case 'GET_LANGUAGES_FAILURE':
            return {
                ...state,
                languagesLoading: false,
            }
        // GET_LANGUAGES_END

        default:
            return state
    }
}

export default languages
