import * as React from 'react'

function SvgComponent(props) {
	const {
		color,
	} = props

	return (
		<svg
			width={12}
			height={3}
			viewBox="0 0 12 3"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M10.813.698H1.187a.45.45 0 00-.437.437v.875c0 .246.191.438.438.438h9.624a.45.45 0 00.438-.438v-.875a.47.47 0 00-.438-.437z"
				fill={color}
			/>
		</svg>
	)
}

const MemoSvgComponent = React.memo(SvgComponent)

export default MemoSvgComponent
