import * as React from 'react'

function SvgComponent(props) {
	const {
		color,
	} = props

	return (
		<svg
			width={25}
			height={14}
			viewBox="0 0 25 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M1.23 2.947H23.09c.49 0 .888-.385.888-.876a.887.887 0 00-.888-.887H1.23a.877.877 0 00-.875.887c0 .49.385.876.875.876zm0 5.092H23.09c.49 0 .888-.385.888-.876a.89.89 0 00-.888-.9H1.23a.88.88 0 00-.875.9c0 .49.385.876.875.876zm0 5.103H23.09a.89.89 0 00.888-.899.877.877 0 00-.888-.876H1.23a.867.867 0 00-.875.876c0 .502.385.9.875.9z"
				fill={color}
				stroke={color}
				strokeWidth={0.4}
			/>
		</svg>
	)
}

const MemoSvgComponent = React.memo(SvgComponent)

export default MemoSvgComponent
