import * as React from "react"

function SvgComponent(props) {
	return (
		<svg
			width={33}
			height={45}
			viewBox="0 0 33 45"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.75.994h27.5A2.75 2.75 0 0133 3.744v38.5a2.75 2.75 0 01-2.75 2.75H2.75A2.75 2.75 0 010 42.244v-38.5A2.75 2.75 0 012.75.994z"
				fill="#C4D5EE"
			/>
			<path
				d="M12.375 9.744a.5.5 0 01.5-.5H24.25a.5.5 0 01.5.5v1.75a.5.5 0 01-.5.5H12.875a.5.5 0 01-.5-.5v-1.75zM12.375 15.244a.5.5 0 01.5-.5H24.25a.5.5 0 01.5.5v1.75a.5.5 0 01-.5.5H12.875a.5.5 0 01-.5-.5v-1.75zM12.375 20.744a.5.5 0 01.5-.5H24.25a.5.5 0 01.5.5v1.75a.5.5 0 01-.5.5H12.875a.5.5 0 01-.5-.5v-1.75zM12.375 26.244a.5.5 0 01.5-.5H24.25a.5.5 0 01.5.5v1.75a.5.5 0 01-.5.5H12.875a.5.5 0 01-.5-.5v-1.75zM12.375 31.744a.5.5 0 01.5-.5H24.25a.5.5 0 01.5.5v1.75a.5.5 0 01-.5.5H12.875a.5.5 0 01-.5-.5v-1.75zM6.875 9.744a.5.5 0 01.5-.5h1.75a.5.5 0 01.5.5v1.75a.5.5 0 01-.5.5h-1.75a.5.5 0 01-.5-.5v-1.75zM6.875 15.244a.5.5 0 01.5-.5h1.75a.5.5 0 01.5.5v1.75a.5.5 0 01-.5.5h-1.75a.5.5 0 01-.5-.5v-1.75zM6.875 20.744a.5.5 0 01.5-.5h1.75a.5.5 0 01.5.5v1.75a.5.5 0 01-.5.5h-1.75a.5.5 0 01-.5-.5v-1.75zM6.875 26.244a.5.5 0 01.5-.5h1.75a.5.5 0 01.5.5v1.75a.5.5 0 01-.5.5h-1.75a.5.5 0 01-.5-.5v-1.75zM6.875 31.744a.5.5 0 01.5-.5h1.75a.5.5 0 01.5.5v1.75a.5.5 0 01-.5.5h-1.75a.5.5 0 01-.5-.5v-1.75z"
				fill="#2868C8"
			/>
		</svg>
	)
}

const MemoSvgComponent = React.memo(SvgComponent)

export default MemoSvgComponent
