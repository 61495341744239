import * as React from 'react'

function SvgComponent(props) {
	return (
		<svg
			width={45}
			height={45}
			viewBox="0 0 45 45"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M41.892 20.98a17.728 17.728 0 01-1.904 8.039 17.981 17.981 0 01-16.077 9.942 17.727 17.727 0 01-8.038-1.904l-12.058 4.02 4.02-12.058A17.727 17.727 0 015.93 20.98a17.98 17.98 0 019.943-16.077A17.727 17.727 0 0123.91 3h1.058a17.939 17.939 0 0116.923 16.923v1.057z"
				fill="#A1BCE4"
			/>
		</svg>
	)
}

const MemoSvgComponent = React.memo(SvgComponent)

export default MemoSvgComponent
