import React, {
    useState,
    useEffect,
    useRef,
} from 'react'

import { AppImage } from 'components'

import styles from './AppSplashScreen.module.scss'

const AppSplashScreen = (props) => {
    const {
        src,
    } = props

    const [isImageShowed, setIsImageShowed] = useState(false)
    const [isSplashScreenShown, setIsSplashScreenShown] = useState(true)

    const containerRef = useRef()

    useEffect(() => {
        document.body.style.overflow = 'hidden'
        document.body.style.touchAction = 'none'
    }, [])

    useEffect(() => {
        if (!isSplashScreenShown) {
            document.body.style.overflow = 'auto'
            document.body.style.touchAction = 'auto'
        }
    }, [isSplashScreenShown])

    const renderAppSplashScreen = () => {
        if (isSplashScreenShown) {
            return (
                <div
                    ref={containerRef}
                    className={styles.container}
                    style={{ opacity: isImageShowed ? 0 : 1 }}
                    onTransitionEnd={(event) => event.target === containerRef.current && setIsSplashScreenShown(false)}
                >
                    <AppImage
                        loading
                        src={src}
                        className={styles.image}
                        onTransitionEnd={() => setIsImageShowed(true)}
                    />
                </div>
            )
        }

        return null
    }

    return renderAppSplashScreen()
}

export default AppSplashScreen
