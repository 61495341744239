import React from 'react'

import { AppButton } from 'components'

import styles from './ServicesListItem.module.scss'

const ServicesListItem = (props) => {
    const {
        item,
        services,
        onSelectService,
    } = props

    return (
        <AppButton
            className={styles.container}
            onClick={() => onSelectService(item)}
        >
            {services[item.service].icon}
            
            <p className={styles.title}>{item.name}</p>
        </AppButton>
    )
}

export default ServicesListItem
