import React from 'react'

import {
    AppButton,
    AppIndicator,
} from 'components'

import styles from './OrderStatus.module.scss'

const OrderStatus = (props) => {
    const {
        status,
        price,
        loading,
        onClick,
    } = props

    const getOrderPlacedClassName = (indicator) => {
        if (status === 'New') {
            if (indicator) {
                return styles.status_unchecked_indicator
            } else {
                return styles.status_unchecked
            }
        }

        if (indicator) {
            return styles.status_checked_indicator
        } else {
            return styles.status_checked
        }
    }

    const getOrderAcceptedClassName = (indicator) => {
        if (status === 'Accepted') {
            if (indicator) {
                return styles.status_checked_indicator
            } else {
                return styles.status_checked
            }
        }

        if (status === 'Declined') {
            if (indicator) {
                return styles.status_declined_indicator
            } else {
                return styles.status_unchecked
            }
        }

        if (indicator) {
            return styles.status_unchecked_indicator
        } else {
            return styles.status_unchecked
        }
    }

    const getStatus = () => {
        if (status === 'Declined') {
            return 'Declined'
        }

        return 'Accepted'
    }

    return (
        <div className={styles.container}>
            <div className={styles.status}>
                <div
                    className={getOrderPlacedClassName()}
                    style={{ marginRight: 60 }}
                >
                    <div className={getOrderPlacedClassName('indicator')} />

                    <p className={styles.status_title}>Order placed</p>
                </div>

                <div className={styles.status_separator} />

                <div
                    className={getOrderAcceptedClassName()}
                    style={{ marginLeft: 60 }}
                >
                    <div className={getOrderAcceptedClassName('indicator')} />

                    <p className={styles.status_title}>{getStatus()}</p>
                </div>
            </div>

            <p className={styles.price}>{price}</p>
        
            {
                status === 'New' && (
                    <AppButton
                        className={styles.button}
                        loading={loading}
                        onClick={onClick}
                    >
                        Place order

                        <AppIndicator className={styles.indicator} />
                    </AppButton>
                )
            }
        </div>
    )
}

export default OrderStatus
