const initialState = {
    callWaiterLoading: false,
    billRequestLoading: false,
    leaveFeedbackLoading: false,
}

const features = (state = initialState, action) => {
    switch(action.type) {
        // CALL_WAITER_WAITER_APP_START
        case 'CALL_WAITER_WAITER_APP_REQUEST':
            return {
                ...state,
                callWaiterLoading: true,
            }
        case 'CALL_WAITER_WAITER_APP_SUCCESS':
            return {
                ...state,
                callWaiterLoading: false,
            }
        case 'CALL_WAITER_WAITER_APP_FAILURE':
            return {
                ...state,
                callWaiterLoading: false,
            }
        // CALL_WAITER_WAITER_APP_END

        // BILL_REQUEST_WAITER_APP_START
        case 'BILL_REQUEST_WAITER_APP_REQUEST':
            return {
                ...state,
                billRequestLoading: true,
            }
        case 'BILL_REQUEST_WAITER_APP_SUCCESS':
            return {
                ...state,
                billRequestLoading: false,
            }
        case 'BILL_REQUEST_WAITER_APP_FAILURE':
            return {
                ...state,
                billRequestLoading: false,
            }
        // BILL_REQUEST_WAITER_APP_END

        // LEAVE_FEEDBACK_WAITER_APP_START
        case 'LEAVE_FEEDBACK_WAITER_APP_REQUEST':
            return {
                ...state,
                leaveFeedbackLoading: true,
            }
        case 'LEAVE_FEEDBACK_WAITER_APP_SUCCESS':
            return {
                ...state,
                leaveFeedbackLoading: false,
            }
        case 'LEAVE_FEEDBACK_WAITER_APP_FAILURE':
            return {
                ...state,
                leaveFeedbackLoading: false,
            }
        // LEAVE_FEEDBACK_WAITER_APP_END

        default:
            return state
    }
}

export default features
