import React from 'react'

import styles from './AppSpinner.module.scss'

const AppSpinner = (props) => {
    const {
        className = styles.container,
        style,
    } = props
    
    return (
        <div
            className={className}
            style={style}
        >
            <div className={styles.spinner} />
        </div>
    )
}

export default AppSpinner
