import MenuService from 'services/waiterApp/menu'

function getProductsAction(params = {}, callback) {
    return async (dispatch) => {
        dispatch({ type: 'GET_PRODUCTS_WAITER_APP_REQUEST' })

        try {
            let data = []

            if (params.master) {
                data = await MenuService.getProductsByMasterCategory(params.id)
            } else if (params.id) {
                data = await MenuService.getProductsByCategory(params.id)
            } else {
                data = await MenuService.getProducts()
            }

            const products = data.data.data

            dispatch({
                type: 'GET_PRODUCTS_WAITER_APP_SUCCESS',
                payload: products,
            })

            if (callback) callback()
        } catch (error) {
            dispatch({ type: 'GET_PRODUCTS_WAITER_APP_FAILURE' })
        }
    }
}

function clearProductsAction(params = {}, callback) {
    return (dispatch) => {
        dispatch({ type: 'CLEAR_PRODUCTS_WAITER_APP' })
    
        if (callback) callback()
    }
}

function getProductAction(params = {}, callback) {
    return (dispatch) => {
        dispatch({
            type: 'GET_PRODUCT_WAITER_APP',
            payload: params.id,
        })

        if (callback) callback()
    }
}

function clearProductAction(params = {}, callback) {
    return (dispatch) => {
        dispatch({ type: 'CLEAR_PRODUCT_WAITER_APP' })
    
        if (callback) callback()
    }
}

export {
    getProductsAction,
    clearProductsAction,

    getProductAction,
    clearProductAction,
}
