import React from 'react'

import FeaturesListItem from '../FeaturesListItem'

import styles from './FeaturesList.module.scss'

const FeaturesList = (props) => {
    const {
        data,
    } = props

    return (
        <div className={styles.container}>
            {
                data.map((item, index) => (
                    <FeaturesListItem
                        key={index}
                        item={item}
                    />
                ))
            }
        </div>
    )
}

export default FeaturesList
