import { axiosInstance } from '../instance'

function createOrder(params) {
	return axiosInstance.post('order/create/table', params)
}

function getOrder(uuid) {
	return axiosInstance.get(`order/${uuid}`)
}

export default {
    createOrder,
    getOrder,
}
