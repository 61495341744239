import React, { useEffect } from 'react'

import {
    useDispatch,
    useSelector,
} from 'react-redux'

import {
    getLanguagesAction,
    updateLanguageAction,
} from 'store/actions/languages'

import {
    AppContainer,
    AppHeader,
} from 'components'

import { LanguagesList } from 'components'

import styles from './Languages.module.scss'

const Languages = (props) => {
    const {
        history,
    } = props

    const languages = useSelector((state) => state.languages.languages)
    const languagesLoading = useSelector((state) => state.languages.languagesLoading)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getLanguagesAction())
    }, [])

    const onSelectLanguage = (language) => {
        const updateLanguageParams = {
            language: language,
        }

        dispatch(updateLanguageAction(updateLanguageParams, history.push('/menu')))
    }

    return (
        <AppContainer className={styles.container}>
            <AppHeader
                type="title"
                title="Languages"
                subtitle="Select your language"
            />

            <LanguagesList
                data={languages}
                loading={languagesLoading}
                onSelectLanguage={onSelectLanguage}
            />
        </AppContainer>
    )
}

export default Languages
