import React, {
	Suspense,
	useEffect,
} from 'react'

import {
	useDispatch,
	useSelector,
} from 'react-redux'

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
	withRouter,
	HashRouter,
	NavLink,
} from 'react-router-dom'

import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { AnimatedSwitch, spring } from 'react-router-transition';

import { checkSessionAction } from './store/actions/session'

import { getOrdersAction as getHotelAppOrdersAction } from 'store/actions/hotelApp/orders'
import { getOrdersAction as getWaiterAppOrdersAction } from 'store/actions/waiterApp/orders'

import {
	subscribeOnChannels,
	unsubscribeOnChannels,
} from './services/socket'

import {
	Home,
	Languages,
	NoMatch,

	HotelAppCategories,
	HotelAppChat,
	HotelAppFeatures,
	HotelAppFeedback,
	HotelAppMenu,
	HotelAppMenuItem,
	HotelAppOrders,
	HotelAppOrder,
	HotelAppRoomService,

	WaiterAppCategories,
    WaiterAppFeatures,
    WaiterAppFeedback,
    WaiterAppMenu,
    WaiterAppMenuItem,
    WaiterAppOrders,
    WaiterAppOrder,
    WaiterAppWaiterService,
    WaiterAppBillRequest,

	LeisureAppMenu,
} from './pages'

import {
	AppSpinner,
	AppSplashScreen,
	AppBottomTabbar,
} from './components'

import { getTabBarVisible } from './utils/helpers'

import './App.scss'

const routes = {
	'hotelAppRoutes': [
		{ path: '/', Component: HotelAppMenu },
		{ path: '/services', Component: Home },
		{ path: '/languages', Component: Languages },
		{ path: '/menu', Component: HotelAppMenu },
		{ path: '/menu/:id', Component: HotelAppMenuItem },
		{ path: '/categories', Component: HotelAppCategories },
		{ path: '/categories/:id', Component: HotelAppCategories },
		{ path: '/orders', Component: HotelAppOrders },
		{ path: '/orders/:id', Component: HotelAppOrder },
		{ path: '/features', Component: HotelAppFeatures },
		{ path: '/features/call-room-service', Component: HotelAppRoomService },
		{ path: '/features/chat', Component: HotelAppChat },
		{ path: '/features/leave-a-feedback', Component: HotelAppFeedback },
		// { path: '/*', Component: NoMatch },
	],
	'waiterAppRoutes': [
		{ path: '/', Component: WaiterAppMenu },
		{ path: '/services', Component: Home },
		{ path: '/languages', Component: Languages },
		{ path: '/menu', Component: WaiterAppMenu },
		{ path: '/menu/:id', Component: WaiterAppMenuItem },
		{ path: '/categories', Component: WaiterAppCategories },
		{ path: '/categories/:id', Component: WaiterAppCategories },
		{ path: '/orders', Component: WaiterAppOrders },
		{ path: '/orders/:id', Component: WaiterAppOrder },
		{ path: '/features', Component: WaiterAppFeatures },
		{ path: '/features/call-waiter', Component: WaiterAppWaiterService },
		{ path: '/features/leave-a-feedback', Component: WaiterAppFeedback },
		{ path: '/features/request-the-bill', Component: WaiterAppBillRequest },
		// { path: '/*', Component: NoMatch },
	],
	'leisureAppRoutes': [
		{ path: '/', Component: LeisureAppMenu },
		{ path: '/services', Component: Home },
		{ path: '/languages', Component: Languages },
		{ path: '/menu', Component: LeisureAppMenu },
		// { path: '/*', Component: NoMatch },
	],
	'defaultRoutes': [
		{ path: '/', Component: Home },
		{ path: '/services', Component: Home },
		{ path: '/languages', Component: Languages },
		// { path: '/*', Component: NoMatch },
	]
}

const favicon = document.getElementById("favicon")
const title = document.getElementById("title")

const App = () => {
	const checkedSession = useSelector((state) => state.session.checkedSession)
	const checkedSessionLoading = useSelector((state) => state.session.checkedSessionLoading)

	const clientOutlet = useSelector((state) => state.session.clientOutlet)

	const currentService = useSelector((state) => state.session.currentService)
	const currentOutlet = useSelector((state) => state.session.currentOutlet)
	const currentRoom = useSelector((state) => state.session.currentRoom)
	const currentTable = useSelector((state) => state.session.currentTable)

	const dispatch = useDispatch()

    useEffect(() => {
		dispatch(checkSessionAction())

		return () => {
			unsubscribeOnChannels(currentService)
		}
    }, [])

	useEffect(() => {
		if (checkedSession && currentService) {
			subscribeOnChannels(currentService)

			if (currentService === 'hotelApp') {
				dispatch(getHotelAppOrdersAction())
			} else if (currentService === 'waiterApp') {
				dispatch(getWaiterAppOrdersAction())
			}
		}
	}, [checkedSession, currentService])

	if (checkedSessionLoading) {
		return <AppSpinner />
	} else if (!checkedSession) {
		return null
	}

	const renderRoute = (path, Component) => {
		if (
			path === '/'
			// && path !== pathname
			// && (currentRoom || currentTable)
		) {
			return (
				<Route
					key={path}
					exact
					path={path}
					render={() => <Redirect to="/menu" />}
				/>
			)
		}

		return (
			<Route
				exact
				key={path}
				path={path}
				component={Component}
			/>
		)
	}

	const renderRoutes = (location) => {
		if (
			location.pathname === '/services'
			&& clientOutlet
		) {
			favicon.href = clientOutlet.logo_image
			title.innerHTML = clientOutlet.name
		}

		if (
			location.pathname !== '/services'
			&& currentOutlet
		) {
			favicon.href = currentOutlet.logo_image
			title.innerHTML = currentOutlet.name
		}

		if (currentService) {
			return routes[`${currentService}Routes`].map(({ path, Component }) => renderRoute(path, Component))
		} else {
			return routes['defaultRoutes'].map(({ path, Component }) => renderRoute(path, Component))
		}
	}

	const renderSplashScreen = (location) => {
		let src = null

		if (
			location.pathname === '/services'
			&& clientOutlet
		) {
			src = clientOutlet.splash_screen_image
		}

		if (
			location.pathname !== '/services'
			&& currentOutlet
		) {
			src = currentOutlet.splash_screen
		}

		if (src) return <AppSplashScreen src={src} />

		return null
	}

	// const Routing = withRouter(({ location }) => (
	// 	<>
	// 		{renderSplashScreen(location)}

	// 		<Switch location={location}>
	// 			{renderRoutes(location)}
	// 		</Switch>

	// 		{
	// 			currentService
	// 			&& (currentRoom || currentTable)
	// 			&& getTabBarVisible(location)
	// 			&& (
	// 				<AppBottomTabbar
	// 					location={location}
	// 					currentOutlet={currentOutlet}
	// 				/>
	// 			)
	// 		}
	// 	</>
	// ))

	const AnimatedRouting = withRouter(({ location }) => (
		<>
			{renderSplashScreen(location)}

			<AnimatedSwitch
				atEnter={{ opacity: 0 }}
				atLeave={{ opacity: spring(1, { stiffness: 250, damping: 30 }) }}
				atActive={{ opacity: spring(1, { stiffness: 250, damping: 30 }) }}
				mapStyles={(styles) => { return { opacity: styles.opacity }}}
				className="switch-wrapper"
			>
				{renderRoutes(location)}
			</AnimatedSwitch>

			{
				currentService
				&& (
					(currentRoom && currentOutlet.slug === currentRoom.slug)
					||
					(currentTable && currentOutlet.slug === currentTable.slug)
				)
				&& getTabBarVisible(location)
				&& (
					<AppBottomTabbar
						location={location}
						currentOutlet={currentOutlet}
					/>
				)
			}
		</>
	))

	return (
		<Suspense fallback={null}>
			<HashRouter>
				<AnimatedRouting />
			</HashRouter>
		</Suspense>
	)
}

export default App
