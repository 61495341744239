import React, { useEffect } from 'react'

import { useSelector } from 'react-redux'

import {
    AppContainer,
    AppHeader,
} from 'components'

import { FeaturesList } from 'components/WaiterApp'

import {
    BellIcon,
    ChatIcon,
    StarIcon,
    BillIcon,
} from 'assets/icons'

import styles from './Features.module.scss'

const Features = () => {
    const currentOutlet = useSelector((state) => state.session.currentOutlet)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const getFeaturesListData = () => {
        let data = []

        if (currentOutlet?.upgrades?.call_a_waiter) {
            data.push({
                title: 'Call waiter',
                route: 'call-waiter',
                icon: <BellIcon />,
            })
        }

        if (currentOutlet?.upgrades?.live_chat) {
            data.push({
                title: 'Live chat',
                route: 'chat',
                icon: <ChatIcon />,
            })
        }

        if (currentOutlet?.upgrades?.leave_feedback) {
            data.push({
                title: 'Leave a feedback',
                route: 'leave-a-feedback',
                icon: <StarIcon />,
            })
        }

        if (currentOutlet?.upgrades?.ask_a_bill) {
            data.push({
                title: 'Request the bill',
                route: 'request-the-bill',
                icon: <BillIcon />,
            })
        }

        return data
    }

    return (
        <AppContainer className={styles.container}>
            <AppHeader
                type="title"
                title="Features"
            />

            <FeaturesList data={getFeaturesListData()} />
        </AppContainer>
    )
}

export default Features
