import * as React from 'react'

function SvgComponent(props) {
    return (
        <svg
            width={47}
            height={49}
            viewBox="0 0 47 49"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M37.648 38.831a.427.427 0 00.38.624h5.981a2.564 2.564 0 010 5.127H5.555a2.564 2.564 0 110-5.127h26.918c.156 0 .3-.086.375-.223l7.191-13.184a2.063 2.063 0 011.245-.996l.28-.08a1.714 1.714 0 011.992 2.435l-5.908 11.424z"
                fill="#fff"
                stroke="#2868C8"
                strokeWidth={0.855}
                strokeLinejoin="round"
            />
            <path
                d="M8.118 44.582h3.418v1.709a1.71 1.71 0 01-3.418 0v-1.71zM38.882 44.582H42.3v1.709a1.709 1.709 0 11-3.418 0v-1.71z"
                fill="#D8E7FE"
                stroke="#2868C8"
                strokeWidth={0.855}
            />
            <mask id="prefix__a" fill="#fff">
                <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.817 6.525a1.71 1.71 0 00-2.191 0L.617 19.893c-1.226 1.024-.502 3.021 1.095 3.021H33.73c1.598 0 2.322-1.997 1.095-3.02L18.817 6.523zm12.15 13.612L17.72 9.455 4.476 20.137h26.49z"
                />
            </mask>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.817 6.525a1.71 1.71 0 00-2.191 0L.617 19.893c-1.226 1.024-.502 3.021 1.095 3.021H33.73c1.598 0 2.322-1.997 1.095-3.02L18.817 6.523zm12.15 13.612L17.72 9.455 4.476 20.137h26.49z"
                fill="#D8E7FE"
            />
            <path
                d="M16.626 6.525l-.548-.656.548.656zm2.19 0l.548-.656-.547.656zM.618 19.893l-.548-.655.548.655zm34.208 0l-.547.656.547-.656zM17.721 9.455l.537-.665a.855.855 0 00-1.073 0l.536.665zm13.246 10.682v.855a.855.855 0 00.536-1.52l-.536.665zm-26.491 0l-.537-.665a.855.855 0 00.537 1.52v-.855zM17.173 7.18a.855.855 0 011.096 0l1.095-1.311a2.564 2.564 0 00-3.286 0l1.095 1.312zM1.165 20.55L17.173 7.18 16.078 5.87.069 19.238l1.096 1.311zm.547 1.51c-.798 0-1.16-.999-.547-1.51L.069 19.236c-1.84 1.537-.753 4.532 1.643 4.532v-1.71zm32.018 0H1.713v1.709H33.73v-1.71zm.548-1.51c.613.511.25 1.51-.548 1.51v1.709c2.396 0 3.483-2.995 1.643-4.531l-1.095 1.311zM18.268 7.18l16.01 13.37 1.095-1.313L19.364 5.87 18.27 7.18zm-1.083 2.94L30.43 20.802l1.073-1.33L18.258 8.79l-1.073 1.33zM5.012 20.802L18.258 10.12l-1.073-1.33L3.939 19.472l1.073 1.33zm25.955-1.52H4.476v1.71h26.49v-1.71z"
                fill="#2868C8"
                mask="url(#prefix__a)"
            />
            <path
                d="M17.945 4.418a1.71 1.71 0 10-1.709-1.709"
                stroke="#2868C8"
                strokeWidth={0.855}
                strokeLinecap="round"
            />
            <path d="M17.945 4.418v5.127" stroke="#2868C8" strokeWidth={0.855} />
            <rect
                x={8.973}
                y={18.518}
                width={17.945}
                height={16.236}
                rx={0.427}
                fill="#fff"
                stroke="#2868C8"
                strokeWidth={0.855}
            />
            <rect
                x={-0.214}
                y={0.214}
                width={0.427}
                height={7.264}
                rx={0.214}
                transform="matrix(-1 0 0 1 12.39 22.364)"
                fill="#fff"
                stroke="#2868C8"
                strokeWidth={0.427}
            />
            <rect
                x={8.973}
                y={29.627}
                width={17.945}
                height={2.564}
                rx={0.427}
                fill="#D8E7FE"
                stroke="#2868C8"
                strokeWidth={0.855}
            />
        </svg>
    )
}

const MemoSvgComponent = React.memo(SvgComponent)

export default MemoSvgComponent
