import React, {
    useState,
    useEffect,
    useRef,
} from 'react'

import {
    useSelector,
    useDispatch,
} from 'react-redux'

import {
    getMessagesAction,
    clearMessagesAction,
    sendMessageAction,
} from 'store/actions/hotelApp/chat'

import {
    AppContainer,
    AppHeader,
} from 'components'

import {
    ChatList,
    ChatInput,
} from 'components/HotelApp'

import styles from './Chat.module.scss'

const { innerHeight: height } = window

const Chat = (props) => {
    const {
        history,
    } = props

    const [windowHeight, setWindowHeight] = useState(height)

    const [message, setMessage] = useState('')

    const [isModalEmojiOpened, setIsModalEmojiOpened] = useState(false)

    const currentRoom = useSelector((state) => state.session.currentRoom)

    const messages = useSelector((state) => state.hotelAppChat.messages)
    const messagesLoading = useSelector((state) => state.hotelAppChat.messagesLoading)

    const sendMessageLoading = useSelector((state) => state.hotelAppChat.sendMessageLoading)

    const currentUUID = useSelector((state) => state.session.currentUUID)

    const chatListRef = useRef()

    const dispatch = useDispatch()

    useEffect(() => {
        const { innerHeight: height } = window

        setWindowHeight(height)

        window.addEventListener('resize', handleResize)
        window.addEventListener('orientationchange', handleResize)

        const getMessagesParams = {
            currentUUID: currentUUID,
        }

        dispatch(getMessagesAction(getMessagesParams))

        return () => {
            window.removeEventListener('resize', handleResize)
            window.removeEventListener('orientationchange', handleResize)

            dispatch(clearMessagesAction())
        }
    }, [])

    useEffect(() => {
        onScrollChatList()
    }, [messages])

    const handleResize = () => {
        const { innerHeight: height } = window

        setWindowHeight(height)
    }

    const onSendMessage = () => {
        if (message.trim().length > 0) {
            const sendMessageParams = {
                room_id: currentRoom.id,
                session_uuid: currentUUID,
                message: message,
            }

            dispatch(sendMessageAction(sendMessageParams, () => {
                setMessage('')
            }))
        } 
    }

    const onSendMessageByKey = (event) => {
        if (
            event.key
            && event.key === 'Enter'
            && message.trim().length > 0
        ) {
            const sendMessageParams = {
                room_id: currentRoom.id,
                session_uuid: currentUUID,
                message: message,
            }

            dispatch(sendMessageAction(sendMessageParams, () => {
                setMessage('')
            }))
        } 
    }

    const onScrollChatList = () => {
        if (chatListRef && chatListRef.current) {
            chatListRef.current.scrollTo({
                top: chatListRef.current.scrollHeight,
                // behavior: 'smooth',
            })
        }
    }

    const onEmojiClick = () => {
        setIsModalEmojiOpened(!isModalEmojiOpened)

        setTimeout(() => onScrollChatList(), 10)
    }

    return (
        <AppContainer className={styles.container}>
            <AppHeader
                type="back"
                className={styles.header}
                onClose={() => history.goBack()}
            />

            <div
                ref={chatListRef}
                className={styles.chat}
            >
                <ChatList
                    data={messages}
                    loading={messagesLoading}
                />
            </div>

            <ChatInput
                value={message}
                windowHeight={windowHeight}
                sendMessageLoading={sendMessageLoading}
                isModalEmojiOpened={isModalEmojiOpened}
                onEmojiClick={onEmojiClick}
                onChange={setMessage}
                onSendMessage={onSendMessage}
                onSendMessageByKey={onSendMessageByKey}
            />
        </AppContainer>
    )
}

export default Chat
