import React, { useState } from 'react'

import {
    AppModal,
    AppHeader,
    AppButton,
} from 'components'

import IngredientsList from '../IngredientsList'

import styles from './ModalOrder.module.scss'

const ModalOrder = (props) => {
    const {
        selectedProduct,
        loading = false,
        onAddOrder,
        onClose,
    } = props

    const [selectedIngredients, setSelectedIngredients] = useState([])
    const [feedback, setFeedback] = useState('')
    const [symbolsCount, setSymbolsCount] = useState(500)

    const onConfirm = () => {
        const additions = selectedIngredients.map((selectedIngredient) => selectedIngredient.id)

        const onAddOrderParams = {
            note: feedback,
            additions: additions,
        }

        onAddOrder(onAddOrderParams)
    }

    const onChangeSelectedIngredients = (ingredient) => {
        if (selectedIngredients.includes(ingredient)) {
            const filteredIngrediesnts = selectedIngredients.filter((selectedIngredient) => selectedIngredient !== ingredient)
        
            setSelectedIngredients(filteredIngrediesnts)
        
            return
        }

        setSelectedIngredients([...selectedIngredients, ingredient])
    }

    const onInputChange = (event) => {
        const value = event.target.value.trimLeft()

        if (value.length > 500) return

        setFeedback(value)
        setSymbolsCount(500 - value.length)
    }

    return (
        <AppModal
            classNameOverlay={styles.overlay}
            classNameModal={styles.modal}
            onClose={onClose}
        >
            <AppHeader
                type="back"
                title="Add to order"
                onClose={onClose}
            />

            <div className={styles.container}>
                {
                    selectedProduct.additions && (
                        <IngredientsList
                            data={selectedProduct.additions}
                            selectedIngredients={selectedIngredients}
                            onChangeSelectedIngredients={onChangeSelectedIngredients}
                        />
                   )
                }

                <p className={styles.title}>Comments</p>

                <textarea
                    className={styles.input}
                    value={feedback}
                    placeholder="Medium rare"
                    onChange={(event) => onInputChange(event)}
                />

                <div className={styles['input_symbols-count']}>
                    <p>no more than 500 symbols</p>

                    <p>{`left: ${symbolsCount}`}</p>
                </div>

                <div className={styles.footer}>
                    <AppButton
                        className={styles['footer_button-confirm']}
                        loading={loading}
                        onClick={() => onConfirm()}
                    >
                        Confirm
                    </AppButton>
                </div>
            </div>
        </AppModal>
    )
}

export default ModalOrder
