function isString(value) {
    return typeof value === 'string' || value instanceof String
}

function isNumber(value) {
    return typeof value === 'number' && Number.isFinite(value)
}

function isArray(value) {
    return value && typeof value === 'object' && value.constructor === Array
}

function isFunction(value) {
    return typeof value === 'function'
}

function isObject(value) {
    return value && typeof value === 'object' && value.constructor === Object
}

function isNull(value) {
    return value === null
}

function isUndefined(value) {
    return typeof value === 'undefined'
}

function isBoolean (value) {
    return typeof value === 'boolean'
}

function isRegExp(value) {
    return value && typeof value === 'object' && value.constructor === RegExp
}

function isError(value) {
    return value instanceof Error && typeof value.message !== 'undefined'
}

function isDate(value) {
    return value instanceof Date
}

function isSymbol(value) {
    return typeof value === 'symbol'
}

function isUrl(value) {
    let str = ''

    if (value.indexOf('http://', 0) > -1 || value.indexOf('https://', 0) > -1) {
        str = value
    } else {
        str = `https://${value}`
    }

    const regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/

    return !!regex.test(str)
}

export {
    isString,
    isNumber,
    isArray,
    isFunction,
    isObject,
    isNull,
    isUndefined,
    isBoolean,
    isRegExp,
    isError,
    isDate,
    isSymbol,
    isUrl,
}
