import * as React from 'react'

function SvgComponent(props) {
	return (
		<svg
			width={13}
			height={22}
			viewBox="0 0 13 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<mask
				id="prefix__a"
				maskUnits="userSpaceOnUse"
				x={0}
				y={0}
				width={13}
				height={22}
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M10.371.986l-9.363 8.99a1.288 1.288 0 000 1.857l9.364 8.988a1.483 1.483 0 102.055-2.14l-7.843-7.529a.343.343 0 010-.497l7.843-7.53A1.485 1.485 0 0010.37.986z"
					fill="#fff"
				/>
			</mask>

			<g mask="url(#prefix__a)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M10.371.986l-9.363 8.99a1.288 1.288 0 000 1.857l9.364 8.988a1.483 1.483 0 102.055-2.14l-7.843-7.529a.343.343 0 010-.497l7.843-7.53A1.485 1.485 0 0010.37.986z"
					fill="#3D3D3D"
				/>
			</g>
		</svg>
	)
}

const MemoSvgComponent = React.memo(SvgComponent)

export default MemoSvgComponent
