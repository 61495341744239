import * as React from 'react'

function SvgComponent(props) {
	return (
		<svg
			width={39}
			height={45}
			viewBox="0 0 39 45"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				opacity={0.4}
				d="M1.804 31.516h0l.004-.004c.1-.108.205-.22.317-.337.795-.84 1.862-1.967 2.755-3.784 1.019-2.075 1.802-5.023 1.802-9.478 0-6.43 4.48-11.58 10.54-12.845l.397-.083V2.79c0-.599.236-1.171.656-1.593a2.228 2.228 0 013.16 0c.419.422.655.994.655 1.592v2.197l.398.083c6.053 1.266 10.534 6.416 10.534 12.845 0 4.456.783 7.403 1.802 9.478.893 1.817 1.96 2.943 2.755 3.783.111.118.218.23.317.338h0l.004.004c.388.41.603.953.6 1.52-.006 1.166-.917 2.252-2.243 2.252H3.447c-1.325 0-2.236-1.086-2.242-2.253a2.193 2.193 0 01.599-1.519z"
				fill="#2868C8"
				stroke="#2868C8"
			/>

			<path
				d="M14.382 38.538h10.942a5.515 5.515 0 01-1.602 3.89 5.456 5.456 0 01-3.869 1.61c-1.45 0-2.842-.58-3.868-1.61a5.515 5.515 0 01-1.603-3.89z"
				fill="#2868C8"
			/>
		</svg>
	)
}

const MemoSvgComponent = React.memo(SvgComponent)

export default MemoSvgComponent
