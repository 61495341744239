import React from 'react'

import ServicesListItem from '../ServicesListItem'

import styles from './ServicesList.module.scss'

const ServicesList = (props) => {
    const {
        data,
        services,
        onSelectService,
    } = props

    return (
        <div className={styles.container}>
            {
                data.map((service, index) => {
                    return (
                        <ServicesListItem
                            key={index}
                            item={service}
                            services={services}
                            onSelectService={onSelectService}
                        />
                    )
                })
            }
        </div>
    )
}

export default ServicesList
