import store from 'store'

function getTabBarVisible(location) {
    const pagesWithTabs = [
        '/menu',
        '/orders',
        '/features',
        '/features/service-call',
    ]

    if (location && location.pathname) {
        return pagesWithTabs.includes(location.pathname)
    }

    return false
}

function getDate(time) {
    const date = parseInt((new Date().getTime() - new Date(time).getTime()) / 1000, 10)

    if (date < 60 && date >= 1) {
        return `${parseInt(date, 10)} seconds`
    } else if (date / 60 < 60 && date / 60 >= 1) {
        return `${parseInt(date / 60, 10)} ${parseInt(date / 60, 10) > 1 ? 'minutes' : 'minute'}`
    } else if (date / 60 / 60 < 24 && date / 60 / 60 >= 1) {
        return `${parseInt(date / 60 / 60, 10)} ${parseInt(date / 60 / 60, 10) > 1 ? 'hours' : 'hour'}`
    } else if (date / 60 / 60 / 24 >= 1 && date / 60 / 60 / 24 < 7) {
        return `${parseInt(date / 60 / 60 / 24, 10)} ${parseInt(date / 60 / 60 / 24, 10) > 1 ? 'days' : 'day'}`
    } else if (date / 60 / 60 / 24 / 7 >= 1 && date / 60 / 60 / 24 / 7 < 5) {
        return `${parseInt(date / 60 / 60 / 24 / 7, 10)} ${parseInt(date / 60 / 60 / 24 / 7, 10) > 1 ? 'weeks' : 'week'}`
    } else if (date / 60 / 60 / 24 / 7 / 4 >= 1) {
        return `${parseInt(date / 60 / 60 / 24 / 7 / 4, 10)} ${parseInt(date / 60 / 60 / 24 / 7 / 4, 10) > 1 ? 'months' : 'month'}`
    }

    return '1 second'
}

function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8)
        
        return v.toString(16)
    })
}

function translate(string) {
    const currentLanguage = store.getState().session.currentLanguage

    return string[currentLanguage]
}

function getCurrency(string = '') {
    const currentOutlet = store.getState().session.currentOutlet
    
    if (currentOutlet.currency) {
        return `${string} ${currentOutlet.currency}`
    }
}

export {
    getTabBarVisible,
    generateUUID,
    getDate,
    translate,
    getCurrency,
}
