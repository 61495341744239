import * as React from 'react'

function SvgComponent(props) {
	const {
		color,
	} = props

	return (
		<svg
			width={22}
			height={21}
			viewBox="0 0 22 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M1 1.75h6a4 4 0 014 4v14a3 3 0 00-3-3H1v-15zM21 1.75h-6a4 4 0 00-4 4v14a3 3 0 013-3h7v-15z"
				stroke={color}
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}

const MemoSvgComponent = React.memo(SvgComponent)

export default MemoSvgComponent
