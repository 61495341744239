import React, { useEffect } from 'react'

import {
    useDispatch,
    useSelector,
} from 'react-redux'

import {
    getOrdersAction,
    clearOrdersAction,
} from 'store/actions/hotelApp/orders'

import {
    AppContainer,
    AppHeader,
} from 'components'

import { OrdersList } from 'components/HotelApp'

import styles from './Orders.module.scss'

const Orders = () => {
    const currentRoom = useSelector((state) => state.session.currentRoom)

    const orders = useSelector((state) => state.hotelAppOrders.orders)
    const ordersLoading = useSelector((state) => state.hotelAppOrders.ordersLoading)

    const dispatch = useDispatch()

    useEffect(() => {
        window.scrollTo(0, 0)

        if (!orders.length) {
            dispatch(getOrdersAction())
        }
    }, [])

    return (
        <AppContainer className={styles.container}>
            <AppHeader
                type="title"
                title="Orders"
                subtitle={`Room ${currentRoom.id}`}
            />

            <OrdersList
                data={orders}
                loading={ordersLoading}
            />
        </AppContainer>
    )
}

export default Orders
