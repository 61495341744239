import * as React from 'react'

function SvgComponent(props) {
	const {
		color,
	} = props

	return (
		<svg
			width={12}
			height={11}
			viewBox="0 0 12 11"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M10.813 4.698H6.874V.76a.47.47 0 00-.438-.437h-.875a.45.45 0 00-.437.437v3.938H1.187a.45.45 0 00-.437.437v.875c0 .246.191.438.438.438h3.937v3.937c0 .246.191.438.438.438h.875a.45.45 0 00.437-.438V6.448h3.938a.45.45 0 00.437-.438v-.875a.47.47 0 00-.438-.437z"
				fill={color}
			/>
		</svg>
	)
}

const MemoSvgComponent = React.memo(SvgComponent)

export default MemoSvgComponent
