import * as React from 'react'

function SvgComponent(props) {
	return (
		<svg
			width={18}
			height={18}
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M8.76.031A8.717 8.717 0 00.04 8.75a8.717 8.717 0 008.719 8.719 8.717 8.717 0 008.719-8.719A8.717 8.717 0 008.758.031zm6.293 5.625h-2.356c-.246-1.265-.632-2.39-1.125-3.304a7.071 7.071 0 013.48 3.304zM8.76 1.72c.633 0 1.687 1.476 2.215 3.937H6.51c.527-2.46 1.582-3.937 2.25-3.937zM1.729 8.75c0-.457.035-.95.14-1.406h2.707c-.035.492-.035.949-.035 1.406 0 .492 0 .95.035 1.406H1.87c-.105-.422-.14-.914-.14-1.406zm.703 3.094h2.355c.246 1.3.633 2.426 1.125 3.34a7.142 7.142 0 01-3.48-3.34zm2.355-6.188H2.432a7.072 7.072 0 013.48-3.304c-.492.914-.879 2.039-1.125 3.304zM8.76 15.781c-.668 0-1.723-1.441-2.25-3.937h4.465c-.528 2.496-1.582 3.937-2.215 3.937zm2.46-5.625H6.265c-.035-.422-.035-.914-.035-1.406 0-.492 0-.95.035-1.406h4.957c.035.457.07.914.07 1.406 0 .492-.035.984-.07 1.406zm.352 5.028c.493-.914.88-2.04 1.125-3.34h2.356a7.141 7.141 0 01-3.48 3.34zm1.336-5.028c.035-.457.07-.914.07-1.406 0-.457-.035-.914-.07-1.406h2.742c.07.457.141.949.141 1.406 0 .492-.07.984-.14 1.406h-2.743z"
				fill="#2868C8"
			/>
		</svg>
	)
}

const MemoSvgComponent = React.memo(SvgComponent)

export default MemoSvgComponent
