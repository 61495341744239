import {
	toast,
	Slide,
} from 'react-toastify'

import store from 'store'

import {
	orderApprovedAction,
	orderDisapprovedAction,
} from 'store/actions/hotelApp/orders'

import {
	messageReceivedAction,
	messageReadAction,
} from 'store/actions/hotelApp/chat'

import { getItem } from 'utils/storage'

const subscribeOnChannelsHotelApp = (socket) => {
	const currentRoom = store.getState().session.currentRoom
	const currentUUID = store.getState().session.currentUUID

	if (currentRoom && currentUUID) {
		const channel = socket.channel(`room.id.${currentRoom.id}`)
		const chatChannel = socket.channel(`chat.session_uuid.${currentUUID}`)

		channel.listen('.App\\Events\\OrderApproved', (res) => {
			console.log(res)

			const orders = getItem('sqr_neo_orders')

			if (orders && res.data.room_id === parseInt(currentRoom.id, 10)) {
				const orderIndex = orders.findIndex((order) => order.uuid === res.data.order_uuid)
				
				if (orders[orderIndex]) {
					toast.success(`Order ${orders[orderIndex].id} - Accepted`, {
						position: 'top-right',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: false,
						draggable: true,
						progress: undefined,
						transition: Slide,
					})
				}

				playNotificationSound()

				store.dispatch(orderApprovedAction(res.data))
			}
		})
		
		channel.listen('.App\\Events\\OrderDisapproved', (res) => {
			console.log(res)

			const orders = getItem('sqr_neo_orders')

			if (orders && res.data.room_id === parseInt(currentRoom.id, 10)) {
				const orderIndex = orders.findIndex((order) => order.uuid === res.data.order_uuid)
				
				if (orders[orderIndex]) {
					toast.error(`Order ${orders[orderIndex].id} - Declined`, {
						position: 'top-right',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: false,
						draggable: true,
						progress: undefined,
						transition: Slide,
					})
				}

				playNotificationSound()

				store.dispatch(orderDisapprovedAction(res.data))
			}
		})

		chatChannel.listen('.App\\Events\\ChatMessageCreated', (res) => {
			console.log(res)

			if (res.data.room_id === parseInt(currentRoom.id, 10)) {
				if (
					res.data.message.type === 'operator'
					&& currentUUID === res.data.message.session_uuid
				) {
					if (window.location.hash !== '#/features/chat') {
						playNotificationSound()

						toast(`New message: ${res.data.message.message}`, {
							position: 'top-right',
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: false,
							draggable: true,
							progress: undefined,
							transition: Slide,
						})
					}

					store.dispatch(messageReceivedAction(res))
				}
			}
		})

		chatChannel.listen('.App\\Events\\ChatMessageRead', (res) => {
			console.log(res)

			if (res.data.room_id === parseInt(currentRoom.id, 10)) {
				store.dispatch(messageReadAction(res))
			}
		})
	}
}

const unsubscribeOnChannelsHotelApp = (socket) => {
	const currentRoom = store.getState().session.currentRoom
	const currentUUID = store.getState().session.currentUUID

	if (currentRoom && currentUUID) {
		socket.leaveChannel(`chat.session_uuid.${currentUUID}`)
		socket.leaveChannel(`room.id.${currentRoom.id}`)
	}
}

function playNotificationSound() {
	const audioElement = document.createElement('audio')

	audioElement.src = '/sounds/notification.mp3'

	document.body.appendChild(audioElement)

	// if (navigator && navigator.vibrate) {
	// 	navigator.vibrate([500, 250, 500, 250, 500, 250, 500, 250, 500, 250, 500])
	// }

	const audioEnded = () => {
		audioElement.removeEventListener('ended', audioEnded)

		document.body.removeChild(audioElement)
	}

	audioElement.addEventListener('ended', audioEnded)

	audioElement.play()
}

export {
	subscribeOnChannelsHotelApp,
	unsubscribeOnChannelsHotelApp,
}
