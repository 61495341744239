import React, {
    useState,
    useEffect,
} from 'react'

import {
    useDispatch,
    useSelector,
} from 'react-redux'

import queryString from 'query-string'

import {
    getProductsAction,
    clearProductsAction,
} from 'store/actions/waiterApp/menu'

import {
    getMasterCategoriesAction,
    clearMasterCategoriesAction,
} from 'store/actions/waiterApp/categories'

import { addOrderAction } from 'store/actions/waiterApp/orders'

import {
    AppContainer,
    AppHeader,
} from 'components'

import {
    MenuList,
    MasterCategoriesList,
    ModalOrder,
} from 'components/WaiterApp'

import { translate } from 'utils/helpers'

import styles from './Menu.module.scss'

const Menu = (props) => {
    const {
        history,
        location,
    } = props

    const [selectedMasterCategory, setSelectedMasterCategory] = useState(null)
    const [selectedProduct, setSelectedProduct] = useState(null)

    const [isModalOrderVisible, setIsModalOrderVisible] = useState(false)

    const currentOutlet = useSelector((state) => state.session.currentOutlet)
    const currentTable = useSelector((state) => state.session.currentTable)

    const masterCategories = useSelector((state) => state.waiterAppCategories.masterCategories)

    const products = useSelector((state) => state.waiterAppMenu.products)
    const productsLoading = useSelector((state) => state.waiterAppMenu.productsLoading)

    const dispatch = useDispatch()

    useEffect(() => {
        window.scrollTo(0, 0)

        if (!masterCategories.length) {
            dispatch(getMasterCategoriesAction({}, (masterCategories) => {
                if (!!masterCategories.length) {
                    if (location.search) {
                        const query = queryString.parse(location.search)
            
                        if (query.masterCategories && query.categories) {
                            const masterCategory = masterCategories.find((masterCategory) => masterCategory.id === parseInt(query.masterCategories, 10))

                            if (masterCategory) {
                                const getProductsParams = {
                                    id: query.categories,
                                }

                                setSelectedMasterCategory(masterCategory)

                                if (!products.length) {
                                    dispatch(getProductsAction(getProductsParams))
                                }
                            }
                        } else if (query.masterCategories) {
                            const masterCategory = masterCategories.find((masterCategory) => masterCategory.id === parseInt(query.masterCategories, 10))
            
                            if (masterCategory) {
                                const getProductsParams = {
                                    id: query.masterCategories,
                                    master: true,
                                }
                                        
                                setSelectedMasterCategory(masterCategory)
            
                                if (!products.length) {
                                    dispatch(getProductsAction(getProductsParams))
                                }
                            }
                        } else if (query.categories) {
                            const getProductsParams = {
                                id: query.categories,
                            }
            
                            if (!products.length) {
                                dispatch(getProductsAction(getProductsParams))
                            }
                        }
                    } else {
                        if (!products.length) {
                            const getProductsParams = {
                                id: masterCategories[0].id,
                                master: true,
                            }

                            setSelectedMasterCategory(masterCategories[0])

                            dispatch(getProductsAction(getProductsParams, () => history.push({
                                pathname: '/menu',
                                search: `?masterCategories=${masterCategories[0].id}`
                            })))
                        }
                    }
                } else {
                    if (location.search) {
                        const query = queryString.parse(location.search)
            
                        if (query.categories) {
                            const getProductsParams = {
                                id: query.categories,
                            }
            
                            if (!products.length) {
                                dispatch(getProductsAction(getProductsParams))
                            }
                        }
                    } else {
                        if (!products.length) {
                            dispatch(getProductsAction())
                        }
                    }
                }
            }))
        } else {
            if (!!masterCategories.length) {
                if (location.search) {
                    const query = queryString.parse(location.search)
        
                    if (query.masterCategories) {
                        const masterCategory = masterCategories.find((masterCategory) => masterCategory.id === parseInt(query.masterCategories, 10))
        
                        if (query.masterCategories && query.categories) {
                            const masterCategory = masterCategories.find((masterCategory) => masterCategory.id === parseInt(query.masterCategories, 10))

                            if (masterCategory) {
                                const getProductsParams = {
                                    id: query.categories,
                                }

                                setSelectedMasterCategory(masterCategory)

                                if (!products.length) {
                                    dispatch(getProductsAction(getProductsParams))
                                }
                            }
                        } else if (masterCategory) {
                            const getProductsParams = {
                                id: query.masterCategories,
                                master: true,
                            }
                                    
                            setSelectedMasterCategory(masterCategory)
        
                            if (!products.length) {
                                dispatch(getProductsAction(getProductsParams))
                            }
                        }
                    } else if (query.categories) {
                        const getProductsParams = {
                            id: query.categories,
                        }
        
                        if (!products.length) {
                            dispatch(getProductsAction(getProductsParams))
                        }
                    }
                } else {
                    if (!products.length) {
                        const getProductsParams = {
                            id: masterCategories[0].id,
                            master: true,
                        }

                        setSelectedMasterCategory(masterCategories[0])

                        dispatch(getProductsAction(getProductsParams, () => history.push({
                            pathname: '/menu',
                            search: `?masterCategories=${masterCategories[0].id}`
                        })))
                    }
                }
            } else {
                if (location.search) {
                    const query = queryString.parse(location.search)
        
                    if (query.categories) {
                        const getProductsParams = {
                            id: query.categories,
                        }
        
                        if (!products.length) {
                            dispatch(getProductsAction(getProductsParams))
                        }
                    }
                } else {
                    if (!products.length) {
                        dispatch(getProductsAction())
                    }
                }
            }
        }
    }, [])

    useEffect(() => {
        if (isModalOrderVisible) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [isModalOrderVisible])

    const onSelectMasterCategory = (category) => {
        if (category === selectedMasterCategory) {
            setSelectedMasterCategory('')

            dispatch(getProductsAction({}, history.push({ pathname: '/menu' })))
        } else {
            const getProductsParams = {
                id: category.id,
                master: true,
            }

            setSelectedMasterCategory(category)

            dispatch(getProductsAction(getProductsParams, history.push({
                pathname: '/menu',
                search: `?masterCategories=${category.id}`
            })))
        }
    }

    const onSelectProduct = (product) => {
        return `${location.pathname}/${product.id}${location.search}`
    }

    const onAddToOrder = (product, quantity) => {
        setIsModalOrderVisible(true)

        const selectedProduct = {
            ...product,
            quantity: quantity,
        }

        setSelectedProduct(selectedProduct)
    }

    const onAddOrder = (order) => {
        const {
            note,
            additions,
        } = order

        const addOrderParams = {
            table_id: currentTable.id,
            note: note,
            grand_total: selectedProduct.price * selectedProduct.quantity,
            grand_quantity: selectedProduct.quantity,
            products: [{
                product_id: selectedProduct.id,
                quantity: selectedProduct.quantity,
                additions: additions,
                product: [{
                    images: selectedProduct.images,
                    description: selectedProduct.description,
                    price: selectedProduct.price,
                }],
            }],
            name: selectedProduct.name,
            weight: selectedProduct.weight,
            categories: selectedProduct.categories,
            status: 'New',
        }

        dispatch(addOrderAction(addOrderParams, () => {
            setIsModalOrderVisible(false)
        }))
    }

    const renderModalOrder = () => {
        if (isModalOrderVisible) {
            return (
                <ModalOrder
                    selectedProduct={selectedProduct}
                    onAddOrder={onAddOrder}
                    onClose={() => setIsModalOrderVisible(false)}   
                />
            )
        }

        return null
    }

    return (
        <AppContainer
            className={styles.container}
            style={{ paddingTop: currentTable && currentOutlet.slug === currentTable.slug ? 58 : 0 }}
        >
            <AppHeader
                type="navbar"
                title={selectedMasterCategory ? translate(selectedMasterCategory.name) : 'Menu'}
                currentTable={currentTable}
            />

            <AppHeader
                type="title"
                title=""
                subtitle={currentOutlet.name}
            />

            <MasterCategoriesList
                data={masterCategories}
                selectedMasterCategory={selectedMasterCategory}
                onSelectMasterCategory={onSelectMasterCategory}
            />

            <MenuList
                data={products}
                loading={productsLoading}
                currentTable={currentTable}
                currentOutlet={currentOutlet}
                onSelectProduct={onSelectProduct}
                onAddToOrder={onAddToOrder}
            />

            {renderModalOrder()}
        </AppContainer>
    )
}

export default Menu
