import React from 'react'

import { AppSpinner } from 'components'

import styles from './AppButton.module.scss'

const AppButton = (props) => {
    const {
        className = styles.container,
        loading = false,
        ...buttonProps
    } = props

    if (loading) {
        return <AppSpinner className={className} />
    }

    return (
        <button
            {...buttonProps} 
            className={className}
        />
    )
}

export default AppButton
