import React, { useState } from 'react'

import { NavLink } from 'react-router-dom'

import { AppIndicator } from 'components'

import {
    translate,
    getCurrency,
} from 'utils/helpers'

import styles from './OrdersListItem.module.scss'

const OrdersListItem = (props) => {
    const {
        item,
    } = props

    const [isImageLoaded, setIsImageLoaded] = useState(false)

    const getStatusColor = () => {
        if (item.status === 'Placed') return '#EFAC00'
        if (item.status === 'Accepted') return '#27AE60'
        if (item.status === 'Declined') return '#DF4759'

        return '#2F80ED'
    }
    
    const renderFooter = () => {
        if (isImageLoaded) {
            return (
                <div className={styles.footer}>
                    <div className={styles.footer_title}>
                        <p>Order {item.id}</p>

                        <p>{getCurrency(item.grand_total)}</p>
                    </div>

                    <div className={styles.footer_description}>
                        <p
                            className={styles.footer_description_status}
                            style={{ color: getStatusColor() }}
                        >
                            {item.status}
                        </p>

                        <span className={styles.footer_description_separator} />

                        <p className={styles.footer_description_amount}>{item.grand_quantity}</p>
                    </div>
                </div>
            )
        }
    }

    return (
        <NavLink
            className={styles.container}
            style={{ padding: isImageLoaded ? '10px 10px 0 10px' : 0 }}
            to={`/orders/${item.id}`}
        >
            <img
                className={styles.image}
                src={item.products[0].product[0].images[0]}
                alt={translate(item.products[0].product[0].description)}
                onLoad={() => setIsImageLoaded(true)}
            />

            {renderFooter()}

            {
                item.status === 'New'
                && <AppIndicator className={styles.indicator} />
            }
        </NavLink>
    )
}

export default OrdersListItem
