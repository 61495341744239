import ChatService from 'services/hotelApp/chat'

import store from '../../index'

function getMessagesAction(params = {}, callback) {
    return async (dispatch) => {
        dispatch({ type: 'GET_MESSAGES_HOTEL_APP_REQUEST' })

        try {
            const data = await ChatService.getMessages(params.currentUUID)

            const messages = data.data.data

            dispatch({
                type: 'GET_MESSAGES_HOTEL_APP_SUCCESS',
                payload: messages,
            })

            if (!!messages.length) {
                const operatorMessages = messages.filter((message) => message.operator)
                
                if (!!operatorMessages.length) {
                    const readMessageParams = {
                        sessionUUID: params.currentUUID,
                        messageId: operatorMessages[operatorMessages.length - 1].id,
                    }

                    store.dispatch(readMessageAction(readMessageParams))
                }
            }

            if (callback) callback()
        } catch (error) {
            dispatch({ type: 'GET_MESSAGES_HOTEL_APP_FAILURE' })
        }
    }
}

function clearMessagesAction(params = {}, callback) {
    return (dispatch) => {
        dispatch({ type: 'CLEAR_MESSAGES_HOTEL_APP' })
    
        if (callback) callback()
    }
}

function sendMessageAction(params = {}, callback) {
    return async (dispatch) => {
        dispatch({ type: 'SEND_MESSAGE_HOTEL_APP_REQUEST' })

        try {
            const data = await ChatService.sendMessage(params)
            
            const message = data.data.data.message

            dispatch({
                type: 'SEND_MESSAGE_HOTEL_APP_SUCCESS',
                payload: message,
            })

            if (callback) callback()
        } catch (error) {
            dispatch({ type: 'SEND_MESSAGE_HOTEL_APP_FAILURE' })
        }
    }
}

function readMessageAction(params = {}, callback) {
    return async (dispatch) => {
        dispatch({ type: 'READ_MESSAGE_HOTEL_APP_REQUEST' })

        try {
            await ChatService.readMessage(params.sessionUUID, params.messageId)

            dispatch({ type: 'READ_MESSAGE_HOTEL_APP_SUCCESS' })

            if (callback) callback()
        } catch (error) {
            dispatch({ type: 'READ_MESSAGE_HOTEL_APP_FAILURE' })
        }
    }
}

function messageReceivedAction(params = {}, callback) {
    return async (dispatch) => {
        const messages = store.getState().hotelAppChat.messages

        dispatch({
            type: 'MESSAGE_RECEIVED_HOTEL_APP',
            payload: params.data.message,
        })

        const readMessageParams = {
            sessionUUID: params.data.message.session_uuid,
            messageId: params.data.message.id,
        }

        if (!!messages.length) {
            store.dispatch(readMessageAction(readMessageParams))
        }

        if (callback) callback()
    }
}

function messageReadAction(params = {}, callback) {
    return (dispatch) => {
        dispatch({
            type: 'MESSAGE_READ_HOTEL_APP',
            payload: params.data,
        })

        if (callback) callback()
    }
}

export {
    getMessagesAction,
    clearMessagesAction,

    sendMessageAction,

    readMessageAction,

    messageReceivedAction,
    messageReadAction,
}
