import * as React from "react"

function SvgComponent(props) {
	return (
		<svg
			width={41}
			height={41}
			viewBox="0 0 41 41"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<circle cx={20.667} cy={20.629} r={20} fill="#2868C8" />

			<g filter="url(#prefix__filter0_dd)">
				<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M22.056 28.962l-.05-.001a.832.832 0 01-.766-.666l-1.282-6.306a.833.833 0 00-.65-.65L13 20.055a.832.832 0 01-.098-1.606l13.334-4.444a.834.834 0 011.054 1.053l-4.445 13.333a.832.832 0 01-.79.57z"
				fill="#fff"
				/>
			</g>

			<defs>
				<filter
					id="prefix__filter0_dd"
					x={8.667}
					y={9.629}
					width={24}
					height={24}
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity={0} result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					/>
					<feOffset dy={1} />
					<feGaussianBlur stdDeviation={0.5} />
					<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
					<feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
					<feColorMatrix
						in="SourceAlpha"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					/>
					<feOffset dy={1} />
					<feGaussianBlur stdDeviation={1} />
					<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
					<feBlend in2="effect1_dropShadow" result="effect2_dropShadow" />
					<feBlend in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
				</filter>
			</defs>
		</svg>
	)
}

const MemoSvgComponent = React.memo(SvgComponent)

export default MemoSvgComponent
