import React, { useState } from 'react'

import {
    useSelector,
    useDispatch,
} from 'react-redux'

import { leaveFeedbackAction } from 'store/actions/hotelApp/features'

import {
    AppContainer,
    AppHeader,
    AppButton,
} from 'components'

import { ModalConfirm } from 'components/HotelApp'

import { StarRoundedIcon } from 'assets/icons'

import styles from './Feedback.module.scss'

const Feedback = (props) => {
    const {
        history,
    } = props

    const [rating, setRating] = useState(5)
    const [feedback, setFeedback] = useState('Lovely')
    const [symbolsCount, setSymbolsCount] = useState(494)

    const [isModalVisible, setIsModalVisible] = useState(false)

    const currentRoom = useSelector((state) => state.session.currentRoom)

    const leaveFeedbackLoading = useSelector((state) => state.hotelAppFeatures.leaveFeedbackLoading)

    const dispatch = useDispatch()

    const onFeedbackChange = (event) => {
        const value = event.target.value.trimLeft()

        if (value.length > 500) return

        setFeedback(value)
        setSymbolsCount(500 - value.length)
    }

    const onLeaveFeedback = () => {
        const leaveFeedbackParams = {
            room_id: currentRoom.id,
            review: feedback,
            points: rating,
        }

        dispatch(leaveFeedbackAction(leaveFeedbackParams, () => {
            setIsModalVisible(true)
        }))
    }

    const getRatingTitle = () => {
        if (rating === 5) return 'Perfect'
        if (rating === 4) return 'Excellent'
        if (rating === 3) return 'Good'
        if (rating === 2) return 'Normal'
        if (rating === 1) return 'Bad'
    }

    const renderModal = () => {
        if (isModalVisible) {
            return (
                <ModalConfirm
                    title="Thank you for the feedback!"
                    subtitle="We use it to make our service better than ever"
                    onClose={() => history.goBack()}
                />
            )
        }

        return null
    }

    return (
        <AppContainer className={styles.container}>
            <AppHeader
                type="back"
                title="Feedback"
                onClose={() => history.goBack()}
            />

            <p className={styles.title}>Describe your impression</p>

            <p className={styles.subtitle}>{getRatingTitle()}</p>

            <div className={styles.rating}>
                {
                    [1, 2, 3, 4, 5].map((item, index) => (
                        <AppButton
                            key={index}
                            onMouseEnter={() => setRating(item)}
                            onClick={() => setRating(item)}
                        >
                            <StarRoundedIcon color={rating >= item ? '#FFD15A' : '#9CA7B5'} />
                        </AppButton>
                    ))
                }
            </div>

            <p className={styles.subtitle}>Feedback</p>

            <textarea
                className={styles.input}
                value={feedback}
                onChange={(event) => onFeedbackChange(event)}
            />

            <div className={styles['input_symbols-count']}>
                <p>no more than 500 symbols</p>

                <p>{`left: ${symbolsCount}`}</p>
            </div>

            {
                rating
                && !!feedback.length
                && (
                    <div className={styles.footer}>
                        <AppButton
                            className={styles['footer_button-confirm']}
                            loading={leaveFeedbackLoading}
                            onClick={() => onLeaveFeedback()}
                        >
                            Send
                        </AppButton>
                    </div>
                )
            }

            {renderModal()}
        </AppContainer>
    )
}

export default Feedback
