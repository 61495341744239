import React, { useRef } from 'react'

import {
    AppButton,
    AppEmojiPicker,
} from 'components'

import {
    EmojiIcon,
    SendIcon,
} from 'assets/icons'

import styles from './ChatInput.module.scss'

const ChatInput = (props) => {
    const {
        value,
        windowHeight,
        sendMessageLoading,
        onChange,
        onSendMessage,
        onSendMessageByKey,
        isModalEmojiOpened,
        onEmojiClick,
    } = props

    const inputRef = useRef()

    const getPickerHeight = () => {
        if (windowHeight > 529) return 300

        return windowHeight - 56 - 72 - 82
    }

    const onEmojiSelected = (event, emojiObject) => {
        inputRef.current.value = `${inputRef.current.value}${emojiObject.emoji}`
        
        onChange(inputRef.current.value)
    }

    const renderModalEmoji = () => {
        if (isModalEmojiOpened) {
            return (
                <AppEmojiPicker
                    height={getPickerHeight()}
                    onEmojiClick={onEmojiSelected}
                />
            )
        }

        return null
    }

    return (
        <div
            className={styles.container}
            style={{ minHeight: isModalEmojiOpened ? getPickerHeight() + 72 : 82 }}
        >
            <div className={styles.input}>
                <input
                    ref={inputRef}
                    type="text"
                    placeholder="Type a message here"
                    value={value}
                    onChange={({ target }) => onChange(target.value)}
                    onKeyDown={onSendMessageByKey}
                />

                <AppButton
                    className={styles.input_button}
                    onClick={() => onEmojiClick()}
                >
                    <EmojiIcon />
                </AppButton>


                <AppButton
                    className={styles.input_button}
                    loading={sendMessageLoading}
                    onClick={onSendMessage}
                >
                    <SendIcon />
                </AppButton>
            </div>

            {renderModalEmoji()}
        </div>
    )
}

export default ChatInput
