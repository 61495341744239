import { axiosInstance } from '../instance'

function getCategories() {
	return axiosInstance.get('categories')
}

function getMasterCategories() {
	return axiosInstance.get('master_categories/with_categories')
}

export default {
    getCategories,
    getMasterCategories,
}
