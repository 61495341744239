import React, { useState } from 'react'

import {
    useDispatch,
    useSelector,
} from 'react-redux'

import { billRequestAction } from 'store/actions/waiterApp/features'

import {
    AppContainer,
    AppHeader,
    AppButton,
} from 'components'

import { ModalConfirm } from 'components/WaiterApp'

import { BillIcon } from 'assets/icons'

import styles from './BillRequest.module.scss'

const BillRequest = (props) => {
    const {
        history,
    } = props

    const [isModalVisible, setIsModalVisible] = useState(false)

    const currentTable = useSelector((state) => state.session.currentTable)

    const billRequestLoading = useSelector((state) => state.waiterAppFeatures.billRequestLoading)

    const dispatch = useDispatch()

    const onBillRequest = () => {
        const billRequestParams = {
            table_id: currentTable.id,
        }

        dispatch(billRequestAction(billRequestParams, () => {
            setIsModalVisible(true)
        }))
    }

    const renderModal = () => {
        if (isModalVisible) {
            return (
                <ModalConfirm
                    title="Great!"
                    subtitle="Waiter has been notified"
                    onClose={() => history.goBack()}
                />
            )
        }

        return null
    }

    return (
        <AppContainer className={styles.container}>
            <AppHeader
                type="back-fixed"
                onClose={() => history.goBack()}
            />

            <div className={styles.call}>
                <AppButton
                    className={styles.call_button}
                    onClick={() => onBillRequest()}
                    loading={billRequestLoading}
                >
                    <BillIcon />

                    <p className={styles.call_button_title}>Tap on me</p>
                </AppButton>

                <p className={styles.call_title}>Request the bill</p>

                <p className={styles.call_subtitle}>The waiter will bring your bill in no time</p>
            </div>

            {renderModal()}
        </AppContainer>
    )
}

export default BillRequest
