import { axiosInstance } from '../instance'

function getMessages(session_uuid) {
	return axiosInstance.get(`chat/session/${session_uuid}/messages`)
}

function sendMessage(params) {
	return axiosInstance.post('chat/add_message', params)
}

function readMessage(session_uuid, message_id) {
	return axiosInstance.get(`chat/session/${session_uuid}/message/${message_id}/read`)
}

export default {
    getMessages,
    sendMessage,
    readMessage,
}
