import { combineReducers } from 'redux'

import session from './session'
import languages from './languages'

import hotelAppCategories from './hotelApp/categories'
import hotelAppChat from './hotelApp/chat'
import hotelAppFeatures from './hotelApp/features'
import hotelAppMenu from './hotelApp/menu'
import hotelAppOrders from './hotelApp/orders'

import waiterAppCategories from './waiterApp/categories'
import waiterAppFeatures from './waiterApp/features'
import waiterAppMenu from './waiterApp/menu'
import waiterAppOrders from './waiterApp/orders'

const reducers = combineReducers({
    session,
    languages,

    hotelAppCategories,
    hotelAppChat,
    hotelAppFeatures,
    hotelAppMenu,
    hotelAppOrders,

    waiterAppCategories,
    waiterAppFeatures,
    waiterAppMenu,
    waiterAppOrders,
})

export default reducers
