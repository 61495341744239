import { axiosInstance } from '../instance'

function callWaiter(params) {
	return axiosInstance.post('calls/waiter', params)
}

function billRequest(params) {
	return axiosInstance.post('calls/bill', params)
}

function leaveFeedback(params) {
	return axiosInstance.post('review/add', params)
}

export default {
    callWaiter,
    billRequest,
    leaveFeedback,
}
