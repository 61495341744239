import React from 'react'

import styles from './AppIndicator.module.scss'

const AppIndicator = (props) => {
    const {
        className,
    } = props

    return (
        <div className={`${styles.container} ${className}`} />
    )
}

export default AppIndicator
