import * as React from 'react'

function SvgComponent(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 14 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.828.142a.665.665 0 00-.936.114L4.19 6.261 1.936 3.463a.667.667 0 00-1.04.836l2.78 3.452a.669.669 0 00.52.249h.005a.665.665 0 00.52-.256l5.22-6.667a.665.665 0 00-.113-.935zm3.333 0a.665.665 0 00-.935.114L7.522 6.261l-.402-.499-.844 1.078.734.911A.669.669 0 007.53 8h.004a.665.665 0 00.52-.256l5.22-6.667a.665.665 0 00-.113-.935zM5.403 3.63L4.56 4.707 4.23 4.3a.667.667 0 011.038-.836l.134.166z"
                fill="#9BA4B7"
            />
        </svg>
    )
}

const MemoSvgComponent = React.memo(SvgComponent)

export default MemoSvgComponent
