import * as React from 'react'

function SvgComponent(props) {
    return (
        <svg
            width={40}
            height={37}
            viewBox="0 0 40 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M19.723 30h19V8.5s-2-8-9.5-8c-5.5 0-9.5 6.5-9.5 6.5v23zM4.723 33.5l-.5-5 6.92-11.863 4.58 1.863-8 14-3 1z"
                fill="#fff"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M38.723 30V10.5a9.5 9.5 0 00-19 0V30h19zm-9.5-30c-5.799 0-10.5 4.701-10.5 10.5V30a1 1 0 001 1h19a1 1 0 001-1V10.5c0-5.799-4.7-10.5-10.5-10.5z"
                fill="#2868C8"
            />
            <path stroke="#2868C8" strokeLinecap="round" d="M25.223 18.5h8" />
            <path d="M18.723 24h21v4h-21v-4z" fill="#D8E7FE" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M38.723 25h-19v2h19v-2zm-20-1v4h21v-4h-21zM29.223 9a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm0 1a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
                fill="#2868C8"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.019 18.366a6.884 6.884 0 106.884-11.924 6.884 6.884 0 00-6.884 11.924zm1.926-3.435a2.918 2.918 0 102.918-5.054 2.918 2.918 0 00-2.918 5.054z"
                fill="#fff"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.548 17.449a5.825 5.825 0 105.826-10.09 5.825 5.825 0 00-5.826 10.09zm-.53.917a6.884 6.884 0 106.885-11.924 6.884 6.884 0 00-6.884 11.924z"
                fill="#2868C8"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.52 15.666a.53.53 0 01.194.724l-7.332 12.7.311 4.156 3.09-1.574 1.245-2.157a.53.53 0 01.224-.21l1.395-.69-.1-1.553a.53.53 0 01.294-.51l1.395-.689-.1-1.553a.529.529 0 01.07-.299l3.177-5.503a.53.53 0 01.917.53l-3.097 5.364.113 1.745a.53.53 0 01-.294.51l-1.395.689.1 1.553a.53.53 0 01-.294.509l-1.568.775-1.244 2.154a.53.53 0 01-.218.207l-3.938 2.007a.53.53 0 01-.768-.432l-.384-5.113a.53.53 0 01.07-.305l7.414-12.84a.53.53 0 01.723-.195zM14.137 14.697a2.648 2.648 0 102.648-4.586 2.648 2.648 0 00-2.648 4.586zm-.53.917a3.707 3.707 0 103.708-6.42 3.707 3.707 0 00-3.707 6.42z"
                fill="#2868C8"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.22 12.823a.483.483 0 10.483-.838.483.483 0 00-.484.838zm-.484.837a1.45 1.45 0 101.45-2.512 1.45 1.45 0 00-1.45 2.512z"
                fill="#2868C8"
            />
            <rect
                x={10.781}
                y={20.403}
                width={0.53}
                height={15.357}
                rx={0.265}
                transform="rotate(30 10.781 20.403)"
                stroke="#2868C8"
                strokeWidth={0.53}
            />
            <path
                d="M29.223 9.5s-1.5 3.34-4.5 4.5c-3.5 1.354-8.223-1-8.223-1"
                stroke="#2868C8"
            />
        </svg>
    )
}

const MemoSvgComponent = React.memo(SvgComponent)

export default MemoSvgComponent
