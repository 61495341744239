import React, { useEffect } from 'react'

import {
    useDispatch,
    useSelector,
} from 'react-redux'

import {
    getCategoriesAction,
    clearCategoriesAction,

    getMasterCategoriesAction,
    clearMasterCategoriesAction,
} from 'store/actions/hotelApp/categories'

import { getProductsAction } from 'store/actions/hotelApp/menu'

import {
    AppContainer,
    AppHeader,
} from 'components'

import { CategoriesList } from 'components/HotelApp'

import styles from './Categories.module.scss'

const Categories = (props) => {
    const {
        history,
        match: { params },
    } = props

    const masterCategories = useSelector((state) => state.hotelAppCategories.masterCategories)
    
    const categories = useSelector((state) => state.hotelAppCategories.categories)
    const categoriesLoading = useSelector((state) => state.hotelAppCategories.categoriesLoading)

    const dispatch = useDispatch()

    useEffect(() => {
        window.scrollTo(0, 0)

        const getCategoriesParams = {
            id: parseInt(params.id, 10),
        }

        if (!masterCategories.length) {
            dispatch(getMasterCategoriesAction({}, (masterCategories) => {
                if (!!masterCategories.length) {
                    const masterCategory = masterCategories.find((masterCategory) => masterCategory.id === getCategoriesParams.id)

                    if (masterCategory) {
                        getCategoriesParams.data = masterCategory.categories
                    } else {
                        getCategoriesParams.id = 'all'
                    }

                    dispatch(getCategoriesAction(getCategoriesParams))
                } else {
                    getCategoriesParams.id = 'all'

                    dispatch(getCategoriesAction(getCategoriesParams))
                }
            }))
        } else {
            if (!!masterCategories.length) {
                const masterCategory = masterCategories.find((masterCategory) => masterCategory.id === getCategoriesParams.id)

                if (masterCategory) {
                    getCategoriesParams.data = masterCategory.categories
                } else {
                    getCategoriesParams.id = 'all'
                }

                dispatch(getCategoriesAction(getCategoriesParams))
            } else {
                getCategoriesParams.id = 'all'

                dispatch(getCategoriesAction(getCategoriesParams))
            }
        }

        return () => {
            dispatch(clearCategoriesAction())
        }
    }, [])

    const onSelectCategory = (category) => {
        const getProductsParams = {
            id: category.id,
        }

        if (getProductsParams.id === 'all') {
            dispatch(getProductsAction({}, history.push({ pathname: '/menu' })))
        } else {
            dispatch(getProductsAction(getProductsParams, history.push({
                pathname: '/menu',
                search: parseInt(params.id, 10) >= 0
                    ? `?masterCategories=${parseInt(params.id, 10)}&categories=${category.id}`
                    : `categories=${category.id}`
            })))
        }
    }

    return (
        <AppContainer className={styles.container}>
            <AppHeader
                type="back"
                title="Categories"
                onClose={() => history.goBack()}
            />

            <CategoriesList
                data={categories}
                loading={categoriesLoading}
                onSelectCategory={onSelectCategory}
            />
        </AppContainer>
    )
}

export default Categories
