import React from 'react'

import { AppButton } from 'components'

import { translate } from 'utils/helpers'

import styles from './CategoriesListItem.module.scss'

const CategoriesListItem = (props) => {
    const {
        item,
        onSelectCategory,
    } = props

    return (
        <AppButton
            className={styles.button}
            style={{
                fontFamily:
                    item.id === 'all'
                    ? 'Inter-Bold'
                    : 'Inter-Medium'
            }}
            onClick={() => onSelectCategory(item)}
        >
            {translate(item.name)}
        </AppButton>
    )
}

export default CategoriesListItem
