import React from 'react'

import { AppSpinner } from 'components'

import LanguagesListItem from '../LanguagesListItem'

import styles from './LanguagesList.module.scss'

const LanguagesList = (props) => {
    const {
        data,
        loading,
        onSelectLanguage,
    } = props

    const renderList = () => {
        if (loading) return <AppSpinner />

        if (!!data.length) {
            return (
                <div className={styles.container}>
                    {
                        data.map((item, index) => (
                            <LanguagesListItem
                                key={index}
                                item={item}
                                onSelectLanguage={onSelectLanguage}
                            />
                        ))
                    }
                </div>
            )
        }

        return null
    }

    return renderList()
}

export default LanguagesList
