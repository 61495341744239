import React from 'react'

import styles from './LeisureMenu.module.scss'

const LeisureMenu = () => {

    return (
        <div className={styles.container}>

        </div>
    )
}

export default LeisureMenu
