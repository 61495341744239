import { axiosInstance } from '../instance'

function getProducts() {
	return axiosInstance.get('products')
}

function getProductsByCategory(category_id) {
	return axiosInstance.get(`products/category/${category_id}`)
}

function getProductsByMasterCategory(master_category_id) {
	return axiosInstance.get(`products/master_category/${master_category_id}`)
}

export default {
    getProducts,
    getProductsByCategory,
    getProductsByMasterCategory,
}
