import React from 'react'

import { AppSpinner } from 'components'

import MenuListItem from '../MenuListItem'

import styles from './MenuList.module.scss'

const MenuList = (props) => {
    const {
        data,
        loading,
        currentRoom,
        currentOutlet,
        onSelectProduct,
        onAddToOrder,
    } = props

    const renderList = () => {
        if (loading) return <AppSpinner />

        if (!!data.length) {
            return (
                <div className={styles.container}>
                    {
                        data.map((item, index) => (
                            <MenuListItem
                                key={index}
                                item={item}
                                currentRoom={currentRoom}
                                currentOutlet={currentOutlet}
                                onSelectProduct={onSelectProduct}
                                onAddToOrder={onAddToOrder}
                            />
                        ))
                    }
                </div>
            )
        }

        return null
    }

    return renderList()
}

export default MenuList
