import React from 'react'

import { translate } from 'utils/helpers'

import styles from './IngredientsListItem.module.scss'

const IngredientsListItem = (props) => {
    const {
        item,
        selectedIngredients,
        onChangeSelectedIngredients,
    } = props

    return (
        <div className={styles.container}>
            <input
                type="checkbox"
                id={item.id}
                className={styles.checkbox}
                onChange={() => onChangeSelectedIngredients(item)}
                checked={selectedIngredients.includes(item)}
            />

            <label htmlFor={item.id}>{translate(item.name)}</label>
        </div>
    )
}

export default IngredientsListItem
