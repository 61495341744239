import React, { useState } from 'react'

import {
    useDispatch,
    useSelector,
} from 'react-redux'

import { callRoomServiceAction } from 'store/actions/hotelApp/features'

import {
    AppContainer,
    AppHeader,
    AppButton,
} from 'components'

import { ModalConfirm } from 'components/HotelApp'

import { BellIcon } from 'assets/icons'

import styles from './RoomService.module.scss'

const RoomService = (props) => {
    const {
        history,
    } = props

    const [isModalVisible, setIsModalVisible] = useState(false)

    const currentRoom = useSelector((state) => state.session.currentRoom)

    const callRoomServiceLoading = useSelector((state) => state.hotelAppFeatures.callRoomServiceLoading)

    const dispatch = useDispatch()

    const onClick = () => {
        const callRoomServiceParams = {
            room_id: currentRoom.id,
        }

        dispatch(callRoomServiceAction(callRoomServiceParams, () => {
            setIsModalVisible(true)
        }))
    }

    const renderModal = () => {
        if (isModalVisible) {
            return (
                <ModalConfirm
                    title="Great!"
                    subtitle="Housekeeping team will do your room."
                    onClose={() => history.goBack()}
                />
            )
        }

        return null
    }

    return (
        <AppContainer className={styles.container}>
            <AppHeader
                type="back-fixed"
                onClose={() => history.goBack()}
            />

            <div className={styles.call}>
                <AppButton
                    className={styles.call_button}
                    onClick={() => onClick()}
                    loading={callRoomServiceLoading}
                >
                    <BellIcon />

                    <p className={styles.call_button_title}>Tap on me</p>
                </AppButton>

                <p className={styles.call_title}>Сall Room service</p>

                <p className={styles.call_subtitle}>Our housekeeping team is at your service</p>
            </div>

            {renderModal()}
        </AppContainer>
    )
}

export default RoomService
