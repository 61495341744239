import React from 'react'

import { AppButton } from 'components'

import { CrossIcon } from 'assets/icons'

import {
    translate,
    getCurrency,
} from 'utils/helpers'

import styles from './OrderListItem.module.scss'

const OrderListItem = (props) => {
    const {
        item,
        onUpdateOrder,
    } = props

    const renderButtonRemove = () => {
        if (item.status === 'New') {
            return (
                <AppButton onClick={() => onUpdateOrder(item.products[0])}>
                    <CrossIcon className={styles['button-remove_icon']} />
                </AppButton>
            )
        }

        return <div />
    }

    return (
        <div className={styles.container}>
            <img
                className={styles.image}
                src={item.products[0].product[0].images[0]}
                alt="product"
            />

            <div className={styles.info}>
                <p className={styles.info_category}>{translate(item.categories[0].name)}</p>

                <p className={styles.info_title}>{translate(item.name)}</p>
            
                {item.weight && <p className={styles.info_weight}>{item.weight} g</p>}
            </div>

            <div className={styles.price}>
                {renderButtonRemove()}

                <p>{getCurrency(item.products[0].product[0].price)}</p>
            </div>
        </div>
    )
}

export default OrderListItem
