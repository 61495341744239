const initialState = {
    products: [],
    productsLoading: false,

    product: null,
}

const menu = (state = initialState, action) => {
    switch(action.type) {
        // GET_PRODUCTS_WAITER_APP_START
        case 'GET_PRODUCTS_WAITER_APP_REQUEST':
            return {
                ...state,
                productsLoading: true,
            }
        case 'GET_PRODUCTS_WAITER_APP_SUCCESS': {
            const products = action.payload.map((product) => {
                product.quantity = 1

                return product
            })

            return {
                ...state,
                products: products,
                productsLoading: false,
            }
        }
        case 'GET_PRODUCTS_WAITER_APP_FAILURE':
            return {
                ...state,
                productsLoading: false,
            }
        // GET_PRODUCTS_WAITER_APP_END

        // CLEAR_PRODUCTS_WAITER_APP_START
        case 'CLEAR_PRODUCTS_WAITER_APP':
            return {
                ...state,
                products: [],
                productsLoading: false,
            }
        // CLEAR_PRODUCTS_WAITER_APP_END

        // GET_PRODUCT_WAITER_APP_START
        case 'GET_PRODUCT_WAITER_APP': {
            const { products } = state

            const product = products.find((product) => product.id === action.payload)

            if (product) {
                return {
                    ...state,
                    product: product,
                } 
            }

            return {
                ...state,
            }
        }
        // GET_PRODUCT_WAITER_APP_END

        // CLEAR_PRODUCT_WAITER_APP_START
        case 'CLEAR_PRODUCT_WAITER_APP':
            return {
                ...state,
                product: null,
            }
        // CLEAR_PRODUCT_WAITER_APP_END

        default:
            return state
    }
}

export default menu
