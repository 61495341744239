const initialState = {
    categories: [],
    categoriesLoading: false,

    masterCategories: [],
    masterCategoriesLoading: false,
}

const categories = (state = initialState, action) => {
    switch(action.type) {
        // GET_CATEGORIES_HOTEL_APP_START
        case 'GET_CATEGORIES_HOTEL_APP_REQUEST':
            return {
                ...state,
                categoriesLoading: true,
            }
        case 'GET_CATEGORIES_HOTEL_APP_SUCCESS':
            return {
                ...state,
                categories: action.payload,
                categoriesLoading: false,
            }
        case 'GET_CATEGORIES_HOTEL_APP_FAILURE':
            return {
                ...state,
                categoriesLoading: false,
            }
        // GET_CATEGORIES_HOTEL_APP_END

        // CLEAR_CATEGORIES_HOTEL_APP_START
        case 'CLEAR_CATEGORIES_HOTEL_APP':
            return {
                ...state,
                categories: [],
                categoriesLoading: false,
            }
        // CLEAR_CATEGORIES_HOTEL_APP_END

        // GET_MASTER_CATEGORIES_HOTEL_APP_START
        case 'GET_MASTER_CATEGORIES_HOTEL_APP_REQUEST':
            return {
                ...state,
                masterCategoriesLoading: true,
            }
        case 'GET_MASTER_CATEGORIES_HOTEL_APP_SUCCESS':
            return {
                ...state,
                masterCategories: action.payload,
                masterCategoriesLoading: false,
            }
        case 'GET_MASTER_CATEGORIES_HOTEL_APP_FAILURE':
            return {
                ...state,
                masterCategoriesLoading: false,
            }
        // GET_MASTER_CATEGORIES_HOTEL_APP_END

        // CLEAR_MASTER_CATEGORIES_HOTEL_APP_START
        case 'CLEAR_MASTER_CATEGORIES_HOTEL_APP':
            return {
                ...state,
                masterCategories: [],
                masterCategoriesLoading: false,
            }
        // CLEAR_MASTER_CATEGORIES_HOTEL_APP_END

        default:
            return state
    }
}

export default categories
