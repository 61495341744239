import React from 'react'
import ReactDOM from 'react-dom'

import {
	ToastContainer,
	Slide,
} from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

import './index.scss'

import store from './store'

import { Provider } from 'react-redux'

import App from './App'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'

import reportWebVitals from './reportWebVitals'

ReactDOM.render(
	<Provider store={store}>
		<ToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss={false}
			draggable
			pauseOnHover={false}
			transition={Slide}
		/>

		<App />
	</Provider>,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
