import React, { useState } from 'react'

import { AppSpinner } from 'components'

import styles from './AppImage.module.scss'

const AppImage = (props) => {
    const {
        src,
        containerClassName = '',
        containerStyle = {},
        imageClassName = '',
        imageStyle = {},
        loading = false,
        onImageLoad,
        onTransitionEnd,
    } = props

    const [isLoaded, setIsLoaded] = useState(false)

    const onLoad = () => {
        setIsLoaded(true)

        if (onImageLoad) onImageLoad()
    }

    return (
        <div
            className={`${styles.container} ${containerClassName}`}
            style={{ ...containerStyle }}
        >
            <img
                src={src}
                className={`${styles.image} ${imageClassName}`}
                style={{
                    ...imageStyle,
                    opacity: isLoaded ? 1 : 0,
                }}
                alt={src}
                onLoad={onLoad}
                onError={onLoad}
                onTransitionEnd={onTransitionEnd}
            />

            {!isLoaded && loading && <AppSpinner />}
        </div>
    )
}

export default AppImage
